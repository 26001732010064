import { NonEmptyArray, IJsonQuery, TModifier } from '../../../packages/JsonQuery';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EJsonQueryActionType {
  JsonQueryChanged = 'filters/jsonQuery/changed',
}

export interface IJsonQueryChanged {
  appliedModifiers: NonEmptyArray<TModifier>;
  prevJsonQuery: IJsonQuery;
  nextJsonQuery: IJsonQuery;
}

export type TJsonQueryChangedAction = ITypedReduxAction<EJsonQueryActionType.JsonQueryChanged, IJsonQueryChanged>;
