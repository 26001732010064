import * as React from 'react';

import { ModalWindow } from '@cian/ui-kit';
import * as styles from './SelectMobileModal.css';

interface ISelectMobileModalProps {
  open: boolean;
  onClose(): void;
  title: string | undefined;
  children: React.ReactNode;
}

export const SelectMobileModal: React.FC<ISelectMobileModalProps> = ({ open, onClose, title, children }) => {
  return (
    <ModalWindow fixed open={open} onClose={onClose} title={title} width={600}>
      <div className={styles['content-container']} data-name="SelectModal">
        {children}
      </div>
    </ModalWindow>
  );
};
