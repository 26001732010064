import { intersection } from 'ramda';

import { getTermsValue, hasTerm } from './helpers';
import { FDealType, FOfferType, NonEmptyArray, IJsonQuery, IJsonQueryOptionalKeys } from './types';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from './utils';

export type TAvailabilityFunction = (jsonQuery: IJsonQuery) => boolean;

export function isAvailable(dealType: FDealType, offerType: FOfferType): TAvailabilityFunction {
  return jsonQuery => {
    const nextDealType = dealTypeFromJsonQuery(jsonQuery);
    const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

    return (dealType & nextDealType) !== 0 && (offerType & nextOfferType) !== 0;
  };
}

export function isAvailableStrict(dealType: FDealType, offerType: FOfferType): TAvailabilityFunction {
  return jsonQuery => {
    const nextDealType = dealTypeFromJsonQuery(jsonQuery);
    const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

    return nextDealType === dealType && nextOfferType === offerType;
  };
}

export function isAvailableRegion(regions: NonEmptyArray<number>): TAvailabilityFunction {
  return jsonQuery => {
    const region = getTermsValue('region')(jsonQuery);

    return !!region && intersection(regions, region).length > 0;
  };
}

export function isAvailableIfKeysUnset(keys: NonEmptyArray<IJsonQueryOptionalKeys>): TAvailabilityFunction {
  return jsonQuery => {
    return !keys.some(k => hasTerm(k)(jsonQuery));
  };
}

export function oneOf(
  ...functions: [TAvailabilityFunction, TAvailabilityFunction, ...TAvailabilityFunction[]]
): TAvailabilityFunction {
  return jsonQuery => {
    return functions.some(f => f(jsonQuery));
  };
}

export function everyOf(
  ...functions: [TAvailabilityFunction, TAvailabilityFunction, ...TAvailabilityFunction[]]
): TAvailabilityFunction {
  return jsonQuery => {
    return functions.every(f => f(jsonQuery));
  };
}
