import { useSelector } from 'react-redux';
import { Recommendations } from '../../components/Recommendations';
import { selectRegionId } from '../../selectors';
import * as React from 'react';
import { Container } from '../../components/Container';
import { useRecommendationsEventsSubscribe } from './hooks/useRecommendationsEventsSubscribe';
import { useApplicationContext } from '../../utils/applicationContext';
import { ECategory } from '../../types/category';
import { ELandingType } from '../../types/landingType';
import { useMicrofrontend } from '@cian/mf-react';
import { RECOMMENDATIONS_MICROFRONTEND_NAME } from '../../constants/microfrontend';

export const RecommendationsContainer = () => {
  const {
    custom: { category },
  } = useApplicationContext();
  const regionId = useSelector(selectRegionId);

  const microfrontend = useMicrofrontend();

  const recommendationsMicrofrontend = microfrontend.getChild(RECOMMENDATIONS_MICROFRONTEND_NAME);

  useRecommendationsEventsSubscribe(recommendationsMicrofrontend.identity.version === '0');

  if (recommendationsMicrofrontend.identity.version === '0') {
    return null;
  }

  return (
    <Container>
      <Recommendations
        regionId={regionId}
        landingType={category === ECategory.FlatRent ? ELandingType.FlatRent : ELandingType.SecondaryFlatSale}
      />
    </Container>
  );
};
