import {
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryRange,
  IJsonQueryDateRange,
  TGeoValue,
  IJsonQueryGeo,
} from './types';
import { NonUndefinable } from './types/common';

export function term<T>(value: NonUndefinable<T> | null): T extends null ? undefined : IJsonQueryTerm<T>;
export function term<T>(value: NonUndefinable<T> | null): IJsonQueryTerm<T> | undefined {
  if (value === null) {
    return undefined;
  }

  return {
    type: 'term',
    value,
  };
}

export function terms<T>(value: NonUndefinable<T>[] | null): T extends null ? undefined : IJsonQueryTerms<T>;
export function terms<T>(value: NonUndefinable<T>[] | null): IJsonQueryTerms<T> | undefined {
  if (!value || value.length === 0) {
    return undefined;
  }

  return {
    type: 'terms',
    value,
  };
}

export function range(gte: number | null, lte: number | null): IJsonQueryRange | undefined {
  if (gte === null && lte === null) {
    return undefined;
  }

  const value: IJsonQueryRange['value'] = {};
  if (gte !== null) {
    value.gte = gte;
  }
  if (lte !== null) {
    value.lte = lte;
  }

  return {
    type: 'range',
    value,
  };
}

export function dateRange(gte: string, lt: string): IJsonQueryDateRange | undefined {
  return {
    type: 'date_range',
    value: {
      gte,
      lt,
    },
  };
}

export function geo(value: TGeoValue[]): IJsonQueryGeo | undefined {
  if (value.length === 0) {
    return undefined;
  }

  return {
    type: 'geo',
    value,
  };
}
