import * as React from 'react';
import { NumberInputWithButtons } from '../../ui/NumberInputWithButtons';
import { plural } from '@cian/utils';

export interface IBedsProps {
  value: number;
  onChange(value: number): void;
  min: number;
  max: number;
}

export const Beds: React.FC<IBedsProps> = ({ value, onChange, min, max }) => {
  const afterText = plural(value, ['гость', 'гостя', 'гостей']);

  return <NumberInputWithButtons value={value} onChange={onChange} min={min} max={max} afterText={afterText} />;
};
