import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSeo } from '../../selectors/seo';
import { Seo } from '../../components/Seo';

export const SeoContainer = () => {
  const { texts, seoTextHeading } = useSelector(selectSeo);

  if (!texts && !seoTextHeading) {
    return null;
  }

  return <Seo title={seoTextHeading} texts={texts} />;
};
