export enum ESearchMetaStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface ISearchMeta {
  /** Кол-во объявлений **/
  count: number;
  /** QueryString **/
  queryString: string;
  /** ЧПУ **/
  seoPath?: string | null;
  /** Поддомен **/
  subdomain: string;
}

export interface ISearchMetaState extends ISearchMeta {
  status: ESearchMetaStatus;
}
