import { HttpCancelError } from '@cian/peperrors/shared';

import { ESearchMetaActionType } from './types';
import { fetchSearchMeta } from '../../services/fetchSearchMeta';
import { TThunkAction } from '../../types/redux';
import { ISearchMeta } from '../../types/searchMeta';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<ESearchMetaActionType.Loading>(ESearchMetaActionType.Loading);
const setStatusSucceed = actionGenerator<ESearchMetaActionType.Succeed, ISearchMeta>(ESearchMetaActionType.Succeed);
const setStatusFailed = actionGenerator<ESearchMetaActionType.Failed>(ESearchMetaActionType.Failed);

export function updateSearchMeta(): TThunkAction<Promise<ISearchMeta | null>> {
  return async (dispatch, getState, context) => {
    const { jsonQuery } = getState();

    dispatch(setStatusLoading());

    try {
      const searchMeta = await fetchSearchMeta(context, { jsonQuery });
      dispatch(setStatusSucceed(searchMeta));

      return searchMeta;
    } catch (ex) {
      if (ex instanceof HttpCancelError) {
        return null;
      }

      const { logger } = context;

      logger.error(ex);

      dispatch(setStatusFailed());

      return null;
    }
  };
}
