/* eslint-disable max-lines */
import { pipe, clone, reject, isNil } from 'ramda';

import { clearGeo } from './clearGeo';
import { removeGeo } from './removeGeo';
import { resetTerms } from './resetTerms';
import { selectGeo } from './selectGeo';
import { setBeds } from './setBeds';
import { setDates } from './setDates';
import { setDealType } from './setDealType';
import { setOfferType } from './setOfferType';
import { setPriceMax } from './setPriceMax';
import { setPriceMin } from './setPriceMin';
import { setRegionId } from './setRegionId';
import { setRoomTypes } from './setRoomTypes';
import { setVillage } from './setVillage';
import { unsetUnavailableProperties } from './unsetUnavailableProperties';
import {
  IJsonQuery,
  NonEmptyArray,
  FDealType,
  FOfferType,
  ERoomType,
  TGeoValue,
  IJsonQueryOptionalKeys,
  ERoomsTotal,
} from './types';
import { convertProperties } from './convertProperties';
import { setRoomsCount } from './setRoomsCount';

const postprocessJsonQuery = pipe(unsetUnavailableProperties, convertProperties, reject(isNil)) as (
  x: IJsonQuery,
) => IJsonQuery;

export class JsonQuery {
  private jsonQuery: IJsonQuery;

  public constructor(jsonQuery: IJsonQuery) {
    this.jsonQuery = clone(jsonQuery);
  }

  public toJSON = () => clone(this.jsonQuery);

  public seIJsonQuery = (jsonQuery: IJsonQuery): this => this.wrap(jsonQuery);
  public setRegion = (region: NonEmptyArray<number> | null): this => this.wrap(setRegionId(this.jsonQuery)(region));
  public setDealType = (dealType: FDealType): this => this.wrap(setDealType(this.jsonQuery)(dealType));
  public setOfferType = (offerType: FOfferType): this => this.wrap(setOfferType(this.jsonQuery)(offerType));
  public setRoomTypes = (roomTypes: NonEmptyArray<ERoomType> | null): this =>
    this.wrap(setRoomTypes(this.jsonQuery)(roomTypes));
  public setRoomsCount = (value: ERoomsTotal[] | null): this => this.wrap(setRoomsCount(this.jsonQuery)(value));
  public setBeds = (gte: number | null, lte: number | null): this => this.wrap(setBeds(this.jsonQuery)(gte, lte));
  public setDates = (gte: string, lt: string): this => this.wrap(setDates(this.jsonQuery)(gte, lt));
  public setPriceMin = (minPrice: number | null): this => this.wrap(setPriceMin(this.jsonQuery)(minPrice));
  public setPriceMax = (maxPrice: number | null): this => this.wrap(setPriceMax(this.jsonQuery)(maxPrice));
  public setVillage = (villageId: number | null): this => this.wrap(setVillage(this.jsonQuery)(villageId));
  public selectGeo = (...geoValues: NonEmptyArray<TGeoValue>): this =>
    this.wrap(selectGeo(this.jsonQuery)(...geoValues));
  public removeGeo = (geoValue: TGeoValue): this => this.wrap(removeGeo(this.jsonQuery)(geoValue));
  public clearGeo = (): this => this.wrap(clearGeo(this.jsonQuery)());

  public resetTerms = (terms: NonEmptyArray<IJsonQueryOptionalKeys>): this =>
    this.wrap(resetTerms(this.jsonQuery)(terms));

  private wrap = (nexIJsonQuery: IJsonQuery) => {
    this.jsonQuery = postprocessJsonQuery(nexIJsonQuery);

    return this;
  };
}
