import { getTermsValue, getTermValue } from '../helpers';
import { EBuildingStatus, EObjectType, EOfficeType, ERoomType, FOfferType, IJsonQuery } from '../types';

export const offerTypeFromJsonQuery = (jsonQuery: IJsonQuery): FOfferType => {
  const { _type: type } = jsonQuery;

  if (type.startsWith('flat')) {
    const room = getTermsValue('room')(jsonQuery);
    const buildingStatus = getTermValue('building_status')(jsonQuery);
    const withNewbuildings = getTermValue('with_newobject')(jsonQuery);

    if (room && room.some(r => [ERoomType.FlatShared, ERoomType.Room, ERoomType.Bed].includes(r))) {
      let offerType = FOfferType.Unexpected;

      if (room.includes(ERoomType.FlatShared)) {
        offerType |= FOfferType.FlatShared;
      }

      if (room.includes(ERoomType.Room)) {
        offerType |= FOfferType.Room;
      }

      if (room.includes(ERoomType.Bed)) {
        offerType |= FOfferType.Bed;
      }

      return offerType;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.New) {
      return FOfferType.FlatNew;
    }

    if (withNewbuildings === true) {
      return FOfferType.FlatNew;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.Old) {
      return FOfferType.FlatOld;
    }

    if (withNewbuildings === false) {
      return FOfferType.FlatOld;
    }

    if (isRent(jsonQuery)) {
      return FOfferType.FlatOld;
    }

    if (type === 'flatsale') {
      return FOfferType.Flat;
    }

    return FOfferType.Urban;
  }

  if (type.startsWith('suburban')) {
    const objectType = getTermsValue('object_type')(jsonQuery);

    if (objectType) {
      let offerType = FOfferType.Unexpected;

      if (objectType.includes(EObjectType.House)) {
        offerType |= FOfferType.House;
      }

      if (objectType.includes(EObjectType.Housepart)) {
        offerType |= FOfferType.HousePart;
      }

      if (objectType.includes(EObjectType.Townhouse)) {
        offerType |= FOfferType.Townhouse;
      }

      if (objectType.includes(EObjectType.Land)) {
        if (isRent(jsonQuery)) {
          offerType |= FOfferType.CommercialLand;
        } else {
          offerType |= FOfferType.Land;
        }
      }

      return offerType;
    }

    return FOfferType.Suburban;
  }

  if (type.startsWith('commercial')) {
    const officeType = getTermsValue('office_type')(jsonQuery);

    if (officeType && officeType.includes(EOfficeType.Garage)) {
      return FOfferType.Garage;
    }
  }

  return FOfferType.Unexpected;
};

function isRent(jsonQuery: IJsonQuery): boolean {
  return jsonQuery._type.endsWith('rent');
}
