import { FOfferType } from './types';

export function isUrban(offerType: FOfferType) {
  return (offerType & FOfferType.Urban) !== 0;
}

export function isSuburban(offerType: FOfferType) {
  return (offerType & FOfferType.Suburban) !== 0;
}

export function isCommercial(offerType: FOfferType) {
  return (offerType & FOfferType.Commercial) !== 0;
}

export function isResidential(offerType: FOfferType) {
  return (offerType & FOfferType.Residential) !== 0;
}

export function isFlat(offerType: FOfferType) {
  return (offerType & FOfferType.Flat) !== 0;
}

export function isRoom(offerType: FOfferType) {
  return (offerType & FOfferType.Room) !== 0;
}
