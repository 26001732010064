import * as React from 'react';
import * as styles from './Geo.css';
import { IconSearch16 } from '@cian/ui-kit';
import classNames from 'classnames';

interface IGeoProps {
  value: string;
  onClick(): void;
}

export const Geo: React.FC<IGeoProps> = ({ value, onClick }) => {
  return (
    <button className={styles['button']} onClick={onClick}>
      <span className={styles['icon_wrapper']}>
        <IconSearch16 display="inline-block" color="gray40_100" />
      </span>
      <span className={classNames(styles['button_text'], !value && styles['button_text_empty'])}>{value}</span>
    </button>
  );
};
