// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetRegionExtensionItemRequest,
  TGetRegionExtensionItemModel,
  IMappers,
  TGetRegionExtensionItemResponse,
  IGetRegionExtensionItemResponse,
  IGetRegionExtensionItemResponseError,
} from './types';

export const defaultConfig: TGetRegionExtensionItemModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'countryside-region-extension',
  pathApi: '/v1/get-region-extension-item/',
} as TGetRegionExtensionItemModel;

export function createGetRegionExtensionItemModel(
  parameters: IGetRegionExtensionItemRequest,
): TGetRegionExtensionItemModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRegionExtensionItemOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetRegionExtensionItemRequest;
}

export async function fetchGetRegionExtensionItem<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetRegionExtensionItemOptions<TResponse200, TResponse400>): Promise<
  TGetRegionExtensionItemResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetRegionExtensionItemModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRegionExtensionItemResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetRegionExtensionItemResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetRegionExtensionItemResponse;
}
