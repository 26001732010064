import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Cell.css';
import * as groupStyles from '../../CellGroup.css';

export interface ICellProps {
  image?: React.ReactNode;
  children: React.ReactChild;
  accessory?: React.ReactNode;
  onClick(): void;
}

export const Cell: React.FC<ICellProps> = ({ image, children, accessory, onClick }) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onClick();
    },
    [onClick],
  );

  return (
    <li className={styles['container']}>
      <a href="#" role="button" onClick={handleClick}>
        <div className={classNames(styles['wrapper'], groupStyles['wrapper'])}>
          {image && <div className={styles['image']}>{image}</div>}
          <div className={styles['content']}>
            <div className={styles['label']}>{children}</div>
            {accessory && <div className={styles['accessory']}>{accessory}</div>}
          </div>
        </div>
      </a>
    </li>
  );
};
