import { IconChevronRight12 } from '@cian/ui-kit';
import * as R from 'ramda';
import * as React from 'react';

import * as styles from './RootOfferType.css';
import { IOfferTypeSelectOption, OfferTypeSelectModal } from './components/OfferTypeSelect';
import { getOfferTypeList } from './helpers';
import { FDealType, FOfferType } from '../../../../../JsonQuery';
import { getOfferTypeLabel } from '../../../utils/offerType';

interface IRootOfferTypeProps {
  dealType: FDealType;
  value: FOfferType;
  onChange(value: FOfferType): void;
}

export const RootOfferType: React.FC<IRootOfferTypeProps> = ({ dealType, value, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const options = React.useMemo(() => getOfferTypeList(dealType, value), [dealType, value]);
  const selectedOptions = React.useMemo(
    () =>
      R.flatten(options.map(t => t.options))
        .filter((offerType: IOfferTypeSelectOption) => (value & offerType.value) !== 0)
        .map((offerType: IOfferTypeSelectOption) => offerType.value),
    [options, value],
  );
  const label = React.useMemo(() => getOfferTypeLabel(dealType, value), [dealType, value]);

  const handleSelect = React.useCallback(
    (nextOfferTypes: FOfferType[]) => {
      const nextOfferType = nextOfferTypes.reduce(
        (previousValue, currentValue) => previousValue | currentValue,
        FOfferType.Unexpected,
      );

      if (nextOfferType !== value && (nextOfferType !== FOfferType.Flat || (value & FOfferType.Flat) === 0)) {
        onChange(nextOfferType);
      }

      setIsOpen(false);
    },
    [onChange, value],
  );

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <button className={styles['button']} onClick={() => setIsOpen(true)}>
        <div className={styles['label']}>{label}</div>
        <div className={styles['arrow']}>
          <IconChevronRight12 display="inline-block" color="primary_100" />
        </div>
      </button>
      <OfferTypeSelectModal
        open={isOpen}
        options={options}
        selected={selectedOptions}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </>
  );
};
