import * as React from 'react';
import { getTermsValue, NonEmptyArray, ERoomsTotal } from '../../../../JsonQuery';
import { useContext } from '../../utils/filtersContext';
import { Rooms } from '../../components/filters/Rooms';
import { getFlatRoomTypeLabel } from '../../utils/flatRoomType';

interface IRoom {
  label: string;
  value: ERoomsTotal;
}

const ROOMS: IRoom[] = [
  { label: '1', value: ERoomsTotal.One },
  { label: '2', value: ERoomsTotal.Two },
  { label: '3', value: ERoomsTotal.Three },
  { label: '4', value: ERoomsTotal.Four },
  { label: '5', value: ERoomsTotal.Five },
];

export const FlatRoomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const rooms = getTermsValue('rooms_count')(jsonQuery);

  const handleChange = React.useCallback(
    (value: NonEmptyArray<ERoomsTotal> | null) => {
      onChange({ action: 'setRoomsCount', arguments: [value] });
    },
    [onChange],
  );

  const label = React.useMemo(() => (rooms ? getFlatRoomTypeLabel(rooms) : null), [rooms]);

  return <Rooms value={rooms} onChange={handleChange} label={label} modalTitle="Комнат в квартире" options={ROOMS} />;
};
