import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { RadioButtonGroup } from '../../ui/RadioButtonGroup';
import * as styles from './FlatType.css';

interface IFlatTypeOption {
  label: string;
  value: FOfferType;
}

const FLAT_TYPE_OPTIONS: IFlatTypeOption[] = [
  { label: 'Все', value: FOfferType.Flat },
  { label: 'Вторичка', value: FOfferType.FlatOld },
  { label: 'Новостройка', value: FOfferType.FlatNew },
];

interface IFlatTypeProps {
  value: FOfferType;
  onChange(value: FOfferType): void;
}

export const FlatType: React.FC<IFlatTypeProps> = ({ value, onChange }) => {
  return (
    <div className={styles['container']}>
      <RadioButtonGroup options={FLAT_TYPE_OPTIONS} value={value} onChange={onChange} />
    </div>
  );
};
