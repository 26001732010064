import * as React from 'react';
import { getRangeValue } from '../../../../JsonQuery';
import { Beds } from '../../components/filters/Beds';
import { useContext } from '../../utils/filtersContext';

const MIN_VALUE = 1;
const MAX_VALUE = 100;
const DEFAULT_VALUE = 1;

function getNormalizedValue(gte: number | undefined): number {
  if (gte === undefined) {
    return DEFAULT_VALUE;
  }

  return Math.max(MIN_VALUE, Math.min(MAX_VALUE, gte));
}

export const BedsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const beds = getRangeValue('beds_count')(jsonQuery);

  const handleChange = React.useCallback(
    (value: number) => {
      const gte = value;
      const lte = null;

      onChange({ action: 'setBeds', arguments: [gte > DEFAULT_VALUE ? gte : null, lte] });
    },
    [onChange],
  );

  const value = getNormalizedValue(beds?.gte);

  return <Beds value={value} onChange={handleChange} min={MIN_VALUE} max={MAX_VALUE} />;
};
