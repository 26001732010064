import { LONGTERM_RENT_OPTIONS } from '../constants';
import { getTermValue } from '../helpers';
import { ERentTime, FDealType, IJsonQuery } from '../types';

export const dealTypeFromJsonQuery = (jsonQuery: IJsonQuery): FDealType => {
  const { _type: type } = jsonQuery;

  if (type.endsWith('sale')) {
    return FDealType.Sale;
  }

  if (type.endsWith('rent')) {
    const rentTime = getTermValue('for_day')(jsonQuery);

    if (rentTime !== null && LONGTERM_RENT_OPTIONS.includes(rentTime)) {
      return FDealType.RentLongterm;
    } else if (rentTime === ERentTime.Daily) {
      return FDealType.RentDaily;
    }

    return FDealType.Rent;
  }

  return FDealType.Unexpected;
};
