import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectRegionLinks } from '../../selectors/regionLinks';
import { RegionLinks } from '../../components/RegionLinks';
import { trackLinkClick, trackShowAll } from './tracking';
import { IRegionLink } from '../../types/regionLinks';
import { selectDealType } from '../../selectors';
import { useApplicationContext } from '../../utils/applicationContext';

export const RegionLinksContainer = () => {
  const {
    custom: { category },
  } = useApplicationContext();
  const regionLinks = useSelector(selectRegionLinks);
  const dealType = useSelector(selectDealType);

  const handleLinkClick = React.useCallback(
    (link: IRegionLink) => {
      const url = new URL(link.url);
      trackLinkClick(link.title, url.search.slice(1), dealType, category);
    },
    [category, dealType],
  );

  const handleToggle = React.useCallback(
    (expanded: boolean) => {
      if (expanded) {
        trackShowAll(dealType, category);
      }
    },
    [category, dealType],
  );

  if (!regionLinks.length) {
    return null;
  }

  return <RegionLinks links={regionLinks} onLinkClick={handleLinkClick} onToggle={handleToggle} />;
};
