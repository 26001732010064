// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetMetaRequest,
  TGetMetaModel,
  IMappers,
  TGetMetaResponse,
  IGetMetaResponse,
  IGetMetaResponseError,
} from './types';

export const defaultConfig: TGetMetaModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'search-offers-index',
  pathApi: '/v1/get-meta/',
  requestType: 'json',
} as TGetMetaModel;

export function createGetMetaModel(parameters: IGetMetaRequest): TGetMetaModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetMetaOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetMetaRequest;
}

export async function fetchGetMeta<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetMetaOptions<TResponse200, TResponse400>): Promise<TGetMetaResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetMetaModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetMetaResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetMetaResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetMetaResponse;
}
