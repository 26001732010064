import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { Cell, ICellProps } from '../Cell';

interface ICellCheckboxProps extends Omit<ICellProps, 'accessory'> {
  checked?: boolean;
}

// FIXME: "input" из Checkbox не может быть внутри "a" из Cell. Надо разрулить эту ситуацию.
export const CellCheckbox: React.FC<ICellCheckboxProps> = ({ checked, onClick, ...sharedProps }) => {
  const handleCheckboxClick = React.useCallback(
    (event: React.MouseEvent<HTMLLabelElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onClick();
    },
    [onClick],
  );

  const handleClick = React.useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Cell
      {...sharedProps}
      accessory={<Checkbox checked={checked} onClick={handleCheckboxClick} />}
      onClick={handleClick}
    />
  );
};
