import * as React from 'react';
import * as styles from './Filters.css';
import { Button, IconFilters16, IconMap16, UIText1 } from '@cian/ui-kit';
import { Container } from '../Container';

interface IFiltersProps {
  filters: JSX.Element;
  onMapClick(): void;
  onSearchClick(): void;
  onAllFiltersClick(): void;
  header: JSX.Element;
  allFiltersUrl: string;
  isUrlUpdating: boolean;
}

export const Filters: React.FC<IFiltersProps> = ({
  header,
  filters,
  onMapClick,
  onSearchClick,
  onAllFiltersClick,
  allFiltersUrl,
  isUrlUpdating,
}) => {
  return (
    <div className={styles['container']}>
      <Container>
        <div className={styles['heading']}>
          {header}
          <a className={styles['all-filters']} role="button" href={allFiltersUrl} onClick={onAllFiltersClick}>
            <IconFilters16 color="primary_100" />
            <div className={styles['filter-text']}>
              <UIText1 color="primary_100" fontWeight="700">
                Все фильтры
              </UIText1>
            </div>
          </a>
        </div>
        {filters}
        <div className={styles['buttons']}>
          <Button
            beforeIcon={<IconMap16 color="primary_100" />}
            theme="fill_white_primary"
            loading={isUrlUpdating}
            onClick={onMapClick}
          >
            На карте
          </Button>
          <Button theme="fill_primary" loading={isUrlUpdating} onClick={onSearchClick}>
            Найти
          </Button>
        </div>
      </Container>
    </div>
  );
};
