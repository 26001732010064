import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';
import { createContext } from './createContext';
import { ensureMicrofrontend } from './internal';

export async function runApp() {
  const context = createContext();

  const store = createApplicationState(context);

  const microfrontend = await ensureMicrofrontend();

  renderApplication({ context, store, microfrontend });
}
