import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectQuickLinks } from '../../selectors/quickLinks';
import { QuickLinks } from '../../components/QuickLinks';
import { IQuickLink } from '../../types/quickLinks';
import { trackLinkClick } from './tracking';
import { selectDealType } from '../../selectors';
import { useApplicationContext } from '../../utils/applicationContext';

export const QuickLinksContainer = () => {
  const {
    custom: { category },
  } = useApplicationContext();
  const quickLinks = useSelector(selectQuickLinks);
  const dealType = useSelector(selectDealType);

  const handleLinkClick = React.useCallback(
    (link: IQuickLink) => {
      const url = new URL(link.url);
      trackLinkClick(link.title, url.search.slice(1), dealType, category);
    },
    [dealType, category],
  );

  if (!quickLinks) {
    return null;
  }

  return (
    <>
      {quickLinks.map((quickLink, index) => (
        <QuickLinks {...quickLink} key={`quick-link-${index}`} onLinkClick={handleLinkClick} />
      ))}
    </>
  );
};
