import { IModalFullscreenProps, ModalFullscreen } from '@cian/ui-kit/modal';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './ModalWindow.css';

export interface IModalWindowProps extends Omit<IModalFullscreenProps, 'theme'> {
  header?: JSX.Element;
  fixed?: boolean;
}

export const ModalWindow: React.FC<IModalWindowProps> = ({ header, children, fixed, ...sharedProps }) => {
  return (
    <ModalFullscreen {...sharedProps}>
      <div className={classNames(styles['container'], fixed && styles['container-fixed'])}>
        {header}
        <div className={styles['content']}>{children}</div>
      </div>
    </ModalFullscreen>
  );
};
