import * as R from 'ramda';

import { resetTerms, setRootType, setTerm } from './helpers';
import { setOfferType } from './setOfferType';
import { ERentTime, FDealType, FOfferType, IJsonQuery, IJsonQueryType } from './types';
import { offerTypeFromJsonQuery } from './utils';
import { DEFAULT_LONGTERM_RENT_OPTION } from './constants';

const FDealTypeName = new Map<FDealType, string>([
  [FDealType.Sale, 'sale'],
  [FDealType.Rent, 'rent'],
  [FDealType.RentLongterm, 'rent'],
  [FDealType.RentDaily, 'rent'],
]);

export function setDealType(jsonQuery: IJsonQuery) {
  return (nextDealType: FDealType): IJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    {
      let nextRootType: IJsonQueryType = jsonQuery._type;
      const nextDealTypeString = FDealTypeName.get(nextDealType);
      if (nextDealTypeString) {
        nextRootType = nextRootType.replace(/rent|sale/, nextDealTypeString) as IJsonQueryType;
      }
      nextJsonQuery = setRootType(nextRootType)(nextJsonQuery);
    }

    /**
     * Если следующий тип сделки не поддерживает текущий тип предложения, переключаем на поддерживаемый
     */
    {
      const offerType = offerTypeFromJsonQuery(jsonQuery);
      const nextOfferType = resetNextOfferType(nextDealType, offerType);
      if (nextOfferType) {
        nextJsonQuery = setOfferType(nextJsonQuery)(nextOfferType);
      }
    }

    {
      nextJsonQuery = resetTerms(['for_day'])(nextJsonQuery);

      if ((nextDealType & FDealType.Rent) !== 0) {
        if (nextDealType === FDealType.RentDaily) {
          nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.Daily);
        } else {
          nextJsonQuery = setTerm('for_day')(nextJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
        }
      }
    }

    return nextJsonQuery;
  };
}

function resetNextOfferType(nextDealType: FDealType, offerType: FOfferType): FOfferType | null {
  switch (nextDealType) {
    case FDealType.RentLongterm:
      if (offerType & FOfferType.Land) {
        return offerType & ~FOfferType.Land || FOfferType.Suburban & ~FOfferType.Land;
      }

      if (offerType & (FOfferType.FlatNew | FOfferType.FlatShared)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.RentDaily:
      if (offerType & FOfferType.Suburban) {
        return FOfferType.House;
      }

      if (offerType & (FOfferType.Any & ~FOfferType.FlatOld & ~FOfferType.Room & ~FOfferType.Bed & ~FOfferType.House)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.Sale:
      if (offerType & (FOfferType.FlatOld | FOfferType.Bed)) {
        return FOfferType.Flat;
      }
      break;
  }

  return null;
}
