import * as R from 'ramda';

import { DEFAULT_LONGTERM_RENT_OPTION, LONGTERM_RENT_OPTIONS } from './constants';
import { getTermValue, resetTerms as reseIJsonQueryTerms, setTerm } from './helpers';
import { IJsonQuery, IJsonQueryOptionalKeys } from './types';

export function resetTerms(jsonQuery: IJsonQuery) {
  return (terms: IJsonQueryOptionalKeys[]): IJsonQuery => {
    let nexIJsonQuery = R.clone(jsonQuery);

    if (terms.includes('for_day')) {
      const rentTime = getTermValue('for_day')(nexIJsonQuery);
      if (rentTime !== null && LONGTERM_RENT_OPTIONS.includes(rentTime)) {
        nexIJsonQuery = setTerm('for_day')(nexIJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
      }
    }

    const ignoredTerms = ['for_day'];
    const termsToReset = terms.filter(t => !ignoredTerms.includes(t));
    nexIJsonQuery = reseIJsonQueryTerms(termsToReset)(nexIJsonQuery);

    return nexIJsonQuery;
  };
}
