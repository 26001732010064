import { FOfferType } from '../../../../../../../JsonQuery';
import { IOfferTypeSelectOptionsGroup } from '../../components/OfferTypeSelect';

export const OFFER_TYPES_SALE_RESIDENTIAL: IOfferTypeSelectOptionsGroup[] = [
  {
    label: 'Квартиры и комнаты',
    options: [
      { label: 'Квартира', value: FOfferType.Flat },
      { label: 'Комната', value: FOfferType.Room },
      { label: 'Доля', value: FOfferType.FlatShared },
    ],
  },
  {
    label: 'Дома и участки',
    options: [
      { label: 'Дом, дача', value: FOfferType.House },
      { label: 'Часть дома', value: FOfferType.HousePart },
      { label: 'Таунхаус', value: FOfferType.Townhouse },
      { label: 'Участок', value: FOfferType.Land },
    ],
  },
  { label: 'Гаражи', options: [{ label: 'Гараж', value: FOfferType.Garage }] },
];
