import * as R from 'ramda';

import { isCommercial } from './category';
import { DEFAULT_LONGTERM_RENT_OPTION, DISJOINT_AVAILABLE_OFFER_TYPES } from './constants';
import { setTerm, setTerms, setRootType, resetTerms, getTermsValue } from './helpers';
import { setDealType } from './setDealType';
import { setRoomTypes } from './setRoomTypes';
import {
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ERoomType,
  FDealType,
  FOfferType,
  IJsonQuery,
  IJsonQueryType,
} from './types';
import { dealTypeFromJsonQuery, isAvailableOfferTypeCombination, offerTypeFromJsonQuery } from './utils';

export function setOfferType(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  return nextOfferType => {
    if (!isAvailableOfferTypeCombination(nextOfferType)) {
      return jsonQuery;
    }

    const nextOfferTypes = getNextOfferTypes(nextOfferType);

    let nexIJsonQuery = R.clone(jsonQuery);

    nexIJsonQuery = resetOfferTypeRelatedTerms(nexIJsonQuery)(nextOfferType);

    return nextOfferTypes.reduce((jsonQuery, offerType) => selectOfferType(jsonQuery)(offerType), nexIJsonQuery);
  };
}

function getNextOfferTypes(nextOfferType: FOfferType): FOfferType[] {
  let nextOfferTypes = DISJOINT_AVAILABLE_OFFER_TYPES.filter(o => (o & nextOfferType) !== 0);

  if ([FOfferType.FlatOld, FOfferType.FlatNew].every(o => nextOfferTypes.includes(o))) {
    nextOfferTypes = nextOfferTypes.filter(o => (o & FOfferType.Flat) === 0);
    nextOfferTypes.push(FOfferType.Flat);
  }

  return nextOfferTypes;
}

function resetOfferTypeRelatedTerms(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  return nextOfferType => {
    let nexIJsonQuery = R.clone(jsonQuery);

    const dealType = dealTypeFromJsonQuery(jsonQuery);
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    nexIJsonQuery = resetTerms(['building_status', 'object_type', 'office_type'])(nexIJsonQuery);

    if (nextOfferType !== FOfferType.FlatNew) {
      nexIJsonQuery = resetTerms(['with_newobject'])(nexIJsonQuery);
    }

    const room = getTermsValue('room')(nexIJsonQuery);
    if (room) {
      nexIJsonQuery = setRoomTypes(nexIJsonQuery)(
        room.filter(r => ![ERoomType.Room, ERoomType.FlatShared, ERoomType.Bed].includes(r)),
      );
    }

    if (isCommercial(offerType) && !isCommercial(nextOfferType)) {
      if (dealType & FDealType.Rent) {
        nexIJsonQuery = setTerm('for_day')(nexIJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
      }
    }

    return nexIJsonQuery;
  };
}

function selectOfferType(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  return nextOfferType => {
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    let nexIJsonQuery = R.clone(jsonQuery);

    switch (nextOfferType) {
      case FOfferType.Flat:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');
        break;
      case FOfferType.FlatOld:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');

        if ((dealType & FDealType.Rent) === 0) {
          nexIJsonQuery = setTerm('building_status')(nexIJsonQuery)(EBuildingStatus.Old);
        }
        break;
      case FOfferType.FlatNew:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');
        nexIJsonQuery = setTerm('building_status')(nexIJsonQuery)(EBuildingStatus.New);
        nexIJsonQuery = setTerm('with_newobject')(nexIJsonQuery)(true);
        break;
      case FOfferType.FlatShared:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');
        nexIJsonQuery = appendRoomType(nexIJsonQuery)(ERoomType.FlatShared);
        break;
      case FOfferType.Room:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');
        nexIJsonQuery = appendRoomType(nexIJsonQuery)(ERoomType.Room);
        break;
      case FOfferType.Bed:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('flat');
        nexIJsonQuery = appendRoomType(nexIJsonQuery)(ERoomType.Bed);
        break;
      case FOfferType.House:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('suburban');
        nexIJsonQuery = appendSuburbanObjectType(nexIJsonQuery)(EObjectType.House);
        break;
      case FOfferType.Townhouse:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('suburban');
        nexIJsonQuery = appendSuburbanObjectType(nexIJsonQuery)(EObjectType.Townhouse);
        break;
      case FOfferType.HousePart:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('suburban');
        nexIJsonQuery = appendSuburbanObjectType(nexIJsonQuery)(EObjectType.Housepart);
        break;
      case FOfferType.Land:
        nexIJsonQuery = setDealType(nexIJsonQuery)(FDealType.Sale);
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('suburban');
        nexIJsonQuery = appendSuburbanObjectType(nexIJsonQuery)(EObjectType.Land);
        break;
      case FOfferType.Garage:
        nexIJsonQuery = setRootOfferType(nexIJsonQuery)('commercial');
        nexIJsonQuery = setTerms('office_type')(nexIJsonQuery)([EOfficeType.Garage]);
        break;
    }

    return nexIJsonQuery;
  };
}

function appendSuburbanObjectType(jsonQuery: IJsonQuery) {
  return (objectType: EObjectType): IJsonQuery => {
    const objectTypes: EObjectType[] = getTermsValue('object_type')(jsonQuery) || [];

    return setTerms('object_type')(jsonQuery)(objectTypes.concat(objectType));
  };
}

function appendRoomType(jsonQuery: IJsonQuery) {
  return (roomType: ERoomType): IJsonQuery => {
    const roomTypes: ERoomType[] = getTermsValue('room')(jsonQuery) || [];

    return setTerms('room')(jsonQuery)(roomTypes.concat(roomType));
  };
}

function setRootOfferType(jsonQuery: IJsonQuery) {
  return (offerType: 'flat' | 'commercial' | 'suburban' | 'newobject'): IJsonQuery => {
    const currentType = jsonQuery._type;

    const nextType: IJsonQueryType = currentType.replace(
      /flat|commercial|suburban|newobject/,
      offerType,
    ) as IJsonQueryType;

    return setRootType(nextType)(jsonQuery);
  };
}
