import { useEventCallback, useToggleState } from '@cian/react-utils';
import { Geo } from '../../components/filters/Geo';
import * as React from 'react';
import { HeaderBackable, ModalWindow } from '../../components/ui/ModalWindow';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../LoadableHelpers';
import loadable from '@loadable/component';
import { useContext } from '../../utils/filtersContext';

const GeoSuggestModalContentLoadable = loadable(() => import('../../../../GeoSuggest'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const GeoContainer: React.FC = () => {
  const { logger, config, httpApi, jsonQuery, onChange, geoName, setGeoName, regions, subdomain } = useContext();
  const { state: isOpen, toggle } = useToggleState();

  const handleRetryLoadContent = useEventCallback(() => {
    GeoSuggestModalContentLoadable.load();
  });

  const handleSelect = useEventCallback((geoName: string) => {
    setGeoName(geoName);
    toggle();
  });

  return (
    <>
      <Geo value={geoName} onClick={toggle} />
      {isOpen && (
        <ModalWindow
          header={<HeaderBackable title="Адрес, район, название объекта" onBack={toggle} />}
          open
          onClose={toggle}
          portal
        >
          <LoadableErrorBoundary logger={logger} onRetry={handleRetryLoadContent}>
            <GeoSuggestModalContentLoadable
              onSelect={handleSelect}
              config={config}
              httpApi={httpApi}
              jsonQuery={jsonQuery}
              onChange={onChange}
              logger={logger}
              regions={regions}
              subdomain={subdomain}
              query={geoName}
            />
          </LoadableErrorBoundary>
        </ModalWindow>
      )}
    </>
  );
};
