import { RadioButtonGroup as UiKitRadioButtonGroup } from '@cian/ui-kit/radio';
import { IOption as IUiKitOption } from '@cian/ui-kit/types';
import * as React from 'react';

type TBaseRadioButtonGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof UiKitRadioButtonGroup>,
  'options' | 'value' | 'onChange'
>;
type TValue = string | number | null | boolean;

interface IOption {
  value: TValue;
  label: string | number;
}

interface IRadioButtonGroupProps extends TBaseRadioButtonGroupProps {
  options: IOption[];
  value: TValue;
  onChange(value: TValue): void;
}

/**
 * Обертка над китовым RadioButtonGroup
 * Для поддержки null и boolean в качестве значения
 */
export function RadioButtonGroup(props: IRadioButtonGroupProps) {
  const { onChange, options: optionsProp } = props;
  const { options, optionsMap } = React.useMemo(() => {
    return optionsProp.reduce(
      (result, item) => {
        const value = String(item.value);

        result.options.push({
          ...item,
          value,
        });

        result.optionsMap[value] = item.value;

        return result;
      },
      {
        options: [] as IUiKitOption[],
        optionsMap: {} as { [value: string]: TValue },
      },
    );
  }, [optionsProp]);

  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(optionsMap[value]);
    },
    [onChange, optionsMap],
  );

  return <UiKitRadioButtonGroup {...props} options={options} value={String(props.value)} onChange={handleChange} />;
}
