import { ca } from '@cian/analytics';
import { getPageExtra } from '../../utils/tracking';
import { FDealType } from '../../../packages/JsonQuery';
import { ECategory } from '../../types/category';

export const trackLinkClick = (title: string, queryString: string, dealType: FDealType, category: ECategory) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'lending_main_page',
    action: 'click_sopr',
    label: title,
    page: {
      extra: getPageExtra({ queryString, dealType, category }),
    },
  });
};
