import { applyMiddleware, CombinedState, combineReducers, compose, createStore as _createStore } from 'redux';
import thunk from 'redux-thunk';

import { IApplicationContext } from '../../types/applicationContext';
import { IApplicationState, IReduxStore, TThunkDispatch } from '../../types/redux';
import { IRegion } from '../../types/region';
import { ISeo } from '../../types/seo';
import { createSimpleReducer } from './createSimpleReducer';
import { TReduxActions } from '../../types/redux/actions';
import {
  geoNameReducer,
  jsonQueryReducer,
  searchMetaReducer,
  platformReducer,
  experimentsReducer,
} from '../../reducers';

interface IThunkExt {
  dispatch: TThunkDispatch;
}

let storeReminder: IReduxStore;

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */
export function createReduxStore(context: IApplicationContext, initialState: IApplicationState, debugEnabled: boolean) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    regionLinks: createSimpleReducer([]),
    seo: createSimpleReducer({} as ISeo),
    searchMeta: searchMetaReducer,
    jsonQuery: jsonQueryReducer,
    region: createSimpleReducer({} as IRegion),
    quickLinks: createSimpleReducer([]),
    geoName: geoNameReducer,
    regions: createSimpleReducer([]),
    platform: platformReducer,
    experiments: experimentsReducer,
  });

  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const storeEnchancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  const createStore = (): IReduxStore =>
    _createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
      reducers,
      initialState,
      storeEnchancers,
    );

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
