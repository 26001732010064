import { CancellationToken } from '@cian/http-api';
import { HttpCancelError } from '@cian/peperrors/shared';

export class CancellationTokenManager {
  private lastTokens: Map<string, CancellationToken>;

  public constructor() {
    this.lastTokens = new Map();
  }

  public getTokenAndCancelPrevious(key: string) {
    if (this.lastTokens.has(key)) {
      (this.lastTokens.get(key) as CancellationToken).cancel(new HttpCancelError(`${key} canceled`));
    }

    const token = new CancellationToken();
    this.lastTokens.set(key, token);

    return token;
  }
}
