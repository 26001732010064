import * as React from 'react';
import classNames from 'classnames';
import { ExpandButton } from '../ExpandButton';
import * as styles from './CollapsedText.css';

interface ICollapsedTextProps {
  children: React.ReactNode;
  collapsedHeight?: number;
}

export const CollapsedText: React.FC<ICollapsedTextProps> = ({ children, collapsedHeight = 0 }) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [expandable, setExpandable] = React.useState(false);

  React.useEffect(() => {
    const textEl = textRef.current;

    /* istanbul ignore if */
    if (!textEl) {
      return;
    }

    const collapsedHeight = textEl.offsetHeight;
    const fullHeigh = textEl.scrollHeight;

    setExpandable(fullHeigh > collapsedHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleExpanded = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <div>
      <div
        ref={textRef}
        style={{ height: expanded ? 'auto' : collapsedHeight }}
        className={classNames({ [styles['collapsed']]: !expanded })}
        data-name="TextBlock"
      >
        {children}
      </div>
      {expandable && (
        <div className={styles['button']}>
          <ExpandButton expanded={expanded} onClick={toggleExpanded} />
        </div>
      )}
    </div>
  );
};
