import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { changeJsonQueryRaw, setGeoName, updateSearchMeta } from '../../actions';
import {
  FiltersContainer as FiltersComponent,
  Provider,
  IOnChangeParameters,
  RootDealTypeContainer,
} from '../../../packages/Filters';
import { useApplicationContext } from '../../utils/applicationContext';
import { Filters } from '../../components/Filters';
import { selectSearchMeta, selectJsonQuery, selectGeoName, selectRegionIds, selectDealType } from '../../selectors';
import { DEFAULT_CIAN_DOMAIN } from '../../constants/config';
import { makeMapUrl, makeSearchUrl, makeAllFiltersUrl } from './utils';
import { useEventCallback } from '@cian/react-utils';
import { TThunkDispatch } from '../../types/redux';
import { ESearchMetaStatus } from '../../types/searchMeta';
import { trackAllFiltersClick, trackMapClick, trackSearchClick } from './tracking';
import { fetchRegionExtensionCached } from '../../services/fetchRegionExtension';
import { JsonQuery, isSuburban, offerTypeFromJsonQuery } from '../../../packages/JsonQuery';
import { selectRegionId } from '../../selectors/region';

export const FiltersContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const context = useApplicationContext();
  const {
    logger,
    httpApi,
    config,
    custom: { subdomain, category },
  } = context;

  const jsonQuery = useSelector(selectJsonQuery);
  const searchMeta = useSelector(selectSearchMeta);
  const geoName = useSelector(selectGeoName);
  const regionIds = useSelector(selectRegionIds);
  const currentRegionId = useSelector(selectRegionId);
  const dealType = useSelector(selectDealType);

  const domain = config.get<string>('mobile-filters-frontend.domain') || DEFAULT_CIAN_DOMAIN;

  const isLoading = React.useMemo(() => searchMeta.status === ESearchMetaStatus.Loading, [searchMeta]);

  const allFiltersUrl = React.useMemo(() => makeAllFiltersUrl(domain, searchMeta), [domain, searchMeta]);

  const handleMapClick = React.useCallback(() => {
    trackMapClick(searchMeta.queryString, dealType, category);
    const mapUrl = makeMapUrl(domain, searchMeta);

    window.location.href = mapUrl;
  }, [searchMeta, dealType, category, domain]);

  const handleSearchClick = React.useCallback(() => {
    trackSearchClick(searchMeta.queryString, dealType, category);
    const searchUrl = makeSearchUrl(domain, searchMeta);

    window.location.href = searchUrl;
  }, [searchMeta, dealType, category, domain]);

  const handleChange = React.useCallback(
    async ({ nextJsonQuery: nextJsonQueryDefault, appliedModifiers }: IOnChangeParameters) => {
      let nextJsonQuery = nextJsonQueryDefault;

      const prevOfferType = offerTypeFromJsonQuery(jsonQuery);
      const nextOfferType = offerTypeFromJsonQuery(nextJsonQuery);

      // Для Загородки используем расширение региона
      if (!isSuburban(prevOfferType) && isSuburban(nextOfferType)) {
        const regionExtension = await fetchRegionExtensionCached(context, { regionId: currentRegionId });
        if (regionExtension) {
          nextJsonQuery = new JsonQuery(nextJsonQuery).setRegion([regionExtension.id]).toJSON();
        }
      } else if (isSuburban(prevOfferType) && !isSuburban(nextOfferType)) {
        nextJsonQuery = new JsonQuery(nextJsonQuery).setRegion([currentRegionId]).toJSON();
      }

      dispatch(changeJsonQueryRaw({ prevJsonQuery: jsonQuery, appliedModifiers, nextJsonQuery }));

      dispatch(updateSearchMeta());
    },
    [context, dispatch, jsonQuery, currentRegionId],
  );

  const handleGeoNameChange = useEventCallback((name: string) => {
    dispatch(setGeoName(name));
  });

  return (
    <Provider
      jsonQuery={jsonQuery}
      logger={logger}
      config={config}
      httpApi={httpApi}
      onChange={handleChange}
      geoName={geoName}
      setGeoName={handleGeoNameChange}
      subdomain={subdomain}
      regions={regionIds}
      category={category}
    >
      <Filters
        filters={<FiltersComponent />}
        header={<RootDealTypeContainer />}
        allFiltersUrl={allFiltersUrl}
        isUrlUpdating={isLoading}
        onMapClick={handleMapClick}
        onSearchClick={handleSearchClick}
        onAllFiltersClick={() => trackAllFiltersClick(dealType, category)}
      />
    </Provider>
  );
};
