/* eslint-disable max-lines */
import { isAvailable } from './availability';
import { terms } from './constructors';
import { getTermsValue } from './helpers';
import { ERoomType, FDealType, FOfferType, IJsonQuery, IJsonQueryKeys } from './types';
import { offerTypeFromJsonQuery } from './utils';

interface IPropertyConfig {
  availability?(jsonQuery: IJsonQuery): boolean;
  converter?(jsonQuery: IJsonQuery): IJsonQuery;
}

export const PROPERTIES: { [key in Required<IJsonQueryKeys>]: IPropertyConfig } = {
  _type: {},
  beds_count: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
  },
  dates: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
  },
  building_status: {},
  engine_version: {},
  with_newobject: {},
  geo: {},
  object_type: {},
  office_type: {},
  for_day: { availability: isAvailable(FDealType.Rent, FOfferType.Residential) },
  kp_id: {},
  price: {},
  region: {},
  room: {
    converter: jsonQuery => {
      const nextOfferType = offerTypeFromJsonQuery(jsonQuery);
      const room = getTermsValue('room')(jsonQuery);

      if (!room || (nextOfferType & FOfferType.Flat) !== 0) {
        return jsonQuery;
      }

      const nextRoom = room.filter(
        t =>
          ![
            ERoomType.FlatOneRoom,
            ERoomType.FlatTwoRooms,
            ERoomType.FlatThreeRooms,
            ERoomType.FlatFourRooms,
            ERoomType.FlatFiveRooms,
            ERoomType.FlatManyRooms,
            ERoomType.FlatFreeLayout,
            ERoomType.Studio,
          ].includes(t),
      );

      return {
        ...jsonQuery,
        room: terms(nextRoom),
      };
    },
  },
  rooms_count: { availability: isAvailable(FDealType.Any, FOfferType.Room | FOfferType.FlatShared | FOfferType.Bed) },
};
