import { createConsumer, createSubscriber, IEvent } from '@cian/events-log';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDealType } from '../../../selectors';
import {
  trackRecommendationsView,
  trackRecommendationItemClick,
  trackTrapRecommendationClick,
  trackRecommendationsTrapView,
} from '../tracking';
import { TRecommendationsEvent } from './types';
import { useApplicationContext } from '../../../utils/applicationContext';

export const useRecommendationsEventsSubscribe = (skip: boolean) => {
  const {
    custom: { category },
  } = useApplicationContext();
  const dealType = useSelector(selectDealType);

  useEffect(() => {
    if (skip) {
      return;
    }

    const consumer = createConsumer({ topic: 'recommendations' });

    const recommendationsSubscriber = createSubscriber((event: IEvent<unknown>) => {
      const eventData = event as TRecommendationsEvent;

      switch (eventData.type) {
        case 'show':
          trackRecommendationsView(dealType, category, eventData.value);

          break;
        case 'click': {
          const { suffix, offerId } = eventData.value;

          if (offerId) {
            trackRecommendationItemClick(dealType, category, eventData.value.products);
          } else if (suffix) {
            trackTrapRecommendationClick(dealType, suffix, category);
          }

          break;
        }
        case 'showTrap':
          trackRecommendationsTrapView(dealType, eventData.value.suffix, category);

          break;
      }
    });

    consumer.subscribe(recommendationsSubscriber);

    return () => {
      consumer.unsubscribe(recommendationsSubscriber);
      consumer.destroy();
    };
  }, [skip, category, dealType]);
};
