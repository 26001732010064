import { isEqual } from 'date-fns';

interface IParams {
  initialFrom: Date;
  initialTo: Date;
  from: Date;
  to: Date;
}

export const getIsResetButtonShow = (params: IParams): boolean => {
  const { initialFrom, initialTo, from, to } = params;

  return !isEqual(initialFrom, from) || !isEqual(initialTo, to);
};
