import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroup } from '../../ui/CheckboxButtonGroup';
import { Button, IconChevronRight12 } from '@cian/ui-kit';
import { useEventCallback, useToggleState } from '@cian/react-utils';
import * as styles from './Rooms.css';
import { SelectMobileModal } from '../../ui/SelectMobileModal/SelectMobileModal';
import classNames from 'classnames';

interface IOption<T> {
  label: string;
  value: T;
}

interface IRoomsProps<T> {
  value: NonEmptyArray<T> | null;
  onChange(value: NonEmptyArray<T> | null): void;
  label: string | null;
  modalTitle: string;
  options: IOption<T>[];
}

export function Rooms<T>({ value, onChange, label, modalTitle, options }: IRoomsProps<T>) {
  const { state: isOpen, toggle } = useToggleState();
  const [selectedOptions, setSelectedOptions] = React.useState(value);

  const handleChange = useEventCallback((options: NonEmptyArray<T> | null) => {
    setSelectedOptions(options);
  });

  const handleApply = React.useCallback(() => {
    onChange(selectedOptions);
    toggle();
  }, [onChange, selectedOptions, toggle]);

  return (
    <>
      <button className={styles['button']} onClick={toggle}>
        <div className={classNames(styles['text'], !label && styles['placeholder'])}>{label || 'Кол-во комнат'}</div>
        <div className={styles['arrow']}>
          <IconChevronRight12 display="inline-block" color="primary_100" />
        </div>
      </button>
      {isOpen && (
        <SelectMobileModal title={modalTitle} open onClose={toggle}>
          <div className={styles['modal-container']}>
            <div className={styles['options']}>
              <CheckboxButtonGroup options={options} value={selectedOptions} onChange={handleChange} />
            </div>
            <Button fullWidth size="L" onClick={handleApply}>
              Применить
            </Button>
          </div>
        </SelectMobileModal>
      )}
    </>
  );
}
