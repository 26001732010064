import { ESearchMetaActionType } from '../../actions/searchMeta';
import { TReduxActions } from '../../types/redux/actions';
import { ESearchMetaStatus, ISearchMetaState } from '../../types/searchMeta';

const defaultState: ISearchMetaState = {
  status: ESearchMetaStatus.Initial,
  count: 0,
  queryString: '',
  seoPath: null,
  subdomain: 'www',
};

export function searchMetaReducer(state: ISearchMetaState = defaultState, action: TReduxActions): ISearchMetaState {
  switch (action.type) {
    case ESearchMetaActionType.Loading:
      return {
        ...state,
        status: ESearchMetaStatus.Loading,
      };
    case ESearchMetaActionType.Succeed:
      return {
        ...state,
        status: ESearchMetaStatus.Succeed,
        ...action.payload,
      };
    case ESearchMetaActionType.Failed:
      return {
        ...state,
        status: ESearchMetaStatus.Failed,
      };
  }

  return state;
}
