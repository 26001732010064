import * as React from 'react';
import classNames from 'classnames';
import * as styles from './CollapsedList.css';
import { ExpandButton } from '../ExpandButton';

export interface ICollapsedListProps {
  children: React.ReactNode;
  collapsedHeight: number;
  onToggle(expanded: boolean): void;
}

export const CollapsedList: React.FC<ICollapsedListProps> = ({ children, collapsedHeight, onToggle }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [expandable, setExpandable] = React.useState(false);

  React.useEffect(() => {
    const contentEl = contentRef.current;

    if (!contentEl) {
      return;
    }

    const fullHeigh = contentEl.scrollHeight;

    setExpandable(fullHeigh > collapsedHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleExpanded = React.useCallback(() => {
    setExpanded(!expanded);
    onToggle(!expanded);
  }, [expanded, setExpanded, onToggle]);

  return (
    <>
      <div
        ref={contentRef}
        className={classNames({ [styles['collapsed']]: !expanded })}
        style={{ maxHeight: expanded ? 'none' : collapsedHeight }}
      >
        {children}
      </div>
      {expandable && (
        <div className={styles['button']}>
          <ExpandButton expanded={expanded} onClick={toggleExpanded} />
        </div>
      )}
    </>
  );
};
