import { ITypedReduxAction } from '../../types/redux/actionType';
import { ISearchMeta } from '../../types/searchMeta';

export enum ESearchMetaActionType {
  Loading = 'searchMeta/updateSearchMeta/loading',
  Succeed = 'searchMeta/updateSearchMeta/succeed',
  Failed = 'searchMeta/updateSearchMeta/failed',
}

export type TUpdateSearchMetaLoading = ITypedReduxAction<ESearchMetaActionType.Loading>;
export type TUpdateSearchMetaSucceed = ITypedReduxAction<ESearchMetaActionType.Succeed, ISearchMeta>;
export type TUpdateSearchMetaFailed = ITypedReduxAction<ESearchMetaActionType.Failed>;

export type TSearchMetaActions = TUpdateSearchMetaLoading | TUpdateSearchMetaSucceed | TUpdateSearchMetaFailed;
