import * as React from 'react';
import { getRangeValue } from '../../../../JsonQuery';
import { Price } from '../../components/filters/Price';
import { useContext } from '../../utils/filtersContext';
import { useDebouncedCallback } from '../../utils/useDebouncedCallback';

export const MaxPriceContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const price = getRangeValue('price')(jsonQuery);

  const [internalValueMin, setInternalValueMin] = React.useState<number | null>(
    typeof price?.gte !== 'undefined' ? price?.gte : null,
  );

  const handlePriceChange = useDebouncedCallback((lte: number | null) => {
    onChange({ action: 'setPriceMax', arguments: [lte] });
  }, 300);

  React.useEffect(() => {
    setInternalValueMin(typeof price?.gte !== 'undefined' ? price?.gte : null);
  }, [price?.gte]);

  return (
    <Price placeholder="Цена до" value={price?.lte} onChange={handlePriceChange} internalValueMin={internalValueMin} />
  );
};
