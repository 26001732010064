import * as React from 'react';

import { FDealType } from '../../../../../JsonQuery';
import { UIHeading2, IconChevronDown16 } from '@cian/ui-kit';
import { SelectMobileModal } from '../../ui/SelectMobileModal/SelectMobileModal';
import * as styles from './RootDealType.css';
import { useEventCallback, useToggleState } from '@cian/react-utils';
import { CellGroup, CellSelect } from '../../ui/CellGroup';

interface IDealType {
  label: string;
  value: FDealType;
}

const DEAL_TYPES: IDealType[] = [
  { label: 'Купить', value: FDealType.Sale },
  { label: 'Снять', value: FDealType.RentLongterm },
  { label: 'Посуточно', value: FDealType.RentDaily },
];

interface IRootDealTypeProps {
  value: FDealType;
  onChange(value: FDealType): void;
  onOpen(): void;
}

export const RootDealType: React.FC<IRootDealTypeProps> = ({ value, onChange, onOpen }) => {
  const { state: isOpen, toggle } = useToggleState();
  const { label } = React.useMemo(() => DEAL_TYPES.find(dealType => dealType.value === value) as IDealType, [value]);

  const handleOpen = useEventCallback(() => {
    toggle();
    onOpen();
  });

  return (
    <>
      <button className={styles['header-button']} onClick={handleOpen}>
        <div className={styles['header']}>
          <UIHeading2>{label}</UIHeading2>
        </div>
        <IconChevronDown16 color="primary_100" />
      </button>
      {isOpen && (
        <SelectMobileModal open title="" onClose={toggle}>
          <CellGroup>
            {DEAL_TYPES.map(option => {
              const selected = value === option.value;

              return (
                <CellSelect
                  key={String(option.value)}
                  selected={selected}
                  onClick={() => {
                    onChange(option.value);
                    toggle();
                  }}
                >
                  {option.label}
                </CellSelect>
              );
            })}
          </CellGroup>
        </SelectMobileModal>
      )}
    </>
  );
};
