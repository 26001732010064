import { getMicrofrontend } from '@cian/mf-registry/browser/interface';
import { RECOMMENDATIONS_MICROFRONTEND_NAME } from '../../../../shared/constants/microfrontend';

export const ensureMicrofrontend = async () => {
  const microfrontend = getMicrofrontend();

  const recommendationsMicrofrontend = microfrontend.getChild(RECOMMENDATIONS_MICROFRONTEND_NAME);

  if (recommendationsMicrofrontend.identity.version !== '0') {
    await recommendationsMicrofrontend.ensure();
  }

  return microfrontend;
};
