import { ca } from '@cian/analytics';
import { getPageExtra } from '../../../../../shared/utils/tracking';
import { FDealType } from '../../../../JsonQuery';
import { ECategory } from '../../../../../shared/types/category';

export const trackOpenDealTypes = (dealType: FDealType, category: ECategory) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'lending_main_page',
    action: 'click_sopr',
    label: 'choose_rubric',
    page: {
      extra: getPageExtra({ dealType, category }),
    },
  });
};
