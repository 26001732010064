import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import * as React from 'react';
import { Provider } from 'react-redux';

import { IApplicationContext } from '../../types/applicationContext';
import { IReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { ContentContainer } from '../ContentContainer';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { initialDeviceType },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitContextProvider deviceType={initialDeviceType}>
          <Provider store={reduxStore}>
            <ContentContainer />
          </Provider>
        </UiKitContextProvider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
