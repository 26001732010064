import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './ActionButtons.css';

interface IActionButtonsProps {
  onReset?(): void;
  onApply?(): void;
}

export const ActionButtons: React.FC<IActionButtonsProps> = ({ onReset, onApply }) => {
  return (
    <div className={styles['container']}>
      {onReset && (
        <div className={styles['action']}>
          <Button theme="fill_secondary" fullWidth onClick={onReset}>
            Сбросить
          </Button>
        </div>
      )}
      {onApply && (
        <div className={styles['action']}>
          <Button theme="fill_primary" fullWidth onClick={onApply}>
            Применить
          </Button>
        </div>
      )}
    </div>
  );
};
