import * as R from 'ramda';

import { setGeo } from './helpers';
import { NonEmptyArray, IJsonQuery, TGeoValue } from './types';

export function selectGeo(jsonQuery: IJsonQuery): (...geoValues: NonEmptyArray<TGeoValue>) => IJsonQuery {
  return (...geoValues) => {
    const nextGeo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    const addedGeoValues = geoValues.filter(v => !nextGeo.some(g => R.equals(v, g)));

    return setGeo()(jsonQuery)([...nextGeo, ...addedGeoValues]);
  };
}
