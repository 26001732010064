import * as R from 'ramda';

import { range, term, terms, geo, dateRange } from './constructors';
import {
  IJsonQuery,
  IJsonQueryDateRange,
  IJsonQueryGeo,
  IJsonQueryOptionalKeys,
  IJsonQueryRange,
  IJsonQueryRangeKeys,
  IJsonQueryTerm,
  IJsonQueryTermKeys,
  IJsonQueryTerms,
  IJsonQueryTermsKeys,
  IJsonQueryType,
  TGeoValue,
} from './types';
import { NonEmptyArray, NonUndefinable, Unpacked } from './types/common';
import { IJsonQueryDateRangeKeys } from './types/jsonQuery/jsonQueryDateRangeKeys';

export function setRootType(value: IJsonQueryType): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => ({
    ...jsonQuery,
    _type: value,
  });
}

export function setTerm<K extends IJsonQueryTermKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: NonUndefinable<IJsonQuery[K]>['value'] | null) => IJsonQuery {
  // @ts-expect-error Expression produces a union type that is too complex to represent
  return jsonQuery => value => R.assoc<IJsonQuery[K], IJsonQuery, K>(key, term(value), jsonQuery) as IJsonQuery;
}

export function setTerms<K extends IJsonQueryTermsKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: Unpacked<NonUndefinable<IJsonQuery[K]>['value']>[] | null) => IJsonQuery {
  // @ts-expect-error Expression produces a union type that is too complex to represent
  return jsonQuery => value => R.assoc<IJsonQuery[K], IJsonQuery, K>(key, terms(value), jsonQuery) as IJsonQuery;
}

export function setRange<K extends IJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: number | null, lte: number | null) => IJsonQuery {
  return jsonQuery => (gte, lte) =>
    R.assoc<IJsonQuery[K], IJsonQuery, K>(key, range(gte, lte), jsonQuery) as IJsonQuery;
}

export function setDateRange<K extends IJsonQueryDateRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: string, lt: string) => IJsonQuery {
  return jsonQuery => (gte, lt) =>
    R.assoc<IJsonQuery[K], IJsonQuery, K>(key, dateRange(gte, lt), jsonQuery) as IJsonQuery;
}

export function setRangeMin<K extends IJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: number | null) => IJsonQuery {
  return jsonQuery => gte =>
    R.assoc<IJsonQuery[K], IJsonQuery, K>(
      key,
      range(gte, R.pathOr(null, [key, 'value', 'lte'], jsonQuery)),
      jsonQuery,
    ) as IJsonQuery;
}

export function setRangeMax<K extends IJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => (lte: number | null) => IJsonQuery {
  return jsonQuery => lte =>
    R.assoc<IJsonQuery[K], IJsonQuery, K>(
      key,
      range(R.pathOr(null, [key, 'value', 'gte'], jsonQuery), lte),
      jsonQuery,
    ) as IJsonQuery;
}

export function setGeo(): (jsonQuery: IJsonQuery) => (value: TGeoValue[]) => IJsonQuery {
  return jsonQuery => value => R.assoc<'geo', IJsonQuery>('geo', geo(value), jsonQuery);
}

export function resetTerms<K extends IJsonQueryOptionalKeys>(keys: K[]): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => {
    let nexIJsonQuery = R.clone(jsonQuery);

    keys.forEach(
      key => (nexIJsonQuery = R.assoc<undefined, IJsonQuery, K>(key, undefined, nexIJsonQuery) as IJsonQuery),
    );

    return nexIJsonQuery;
  };
}

export function isTerm<T>(value: unknown): value is IJsonQueryTerm<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'term';
}

export function isTerms<T>(value: unknown): value is IJsonQueryTerms<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'terms';
}

export function isRange(value: unknown): value is IJsonQueryRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'range';
}

export function isDateRange(value: unknown): value is IJsonQueryDateRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'date_range';
}

export function hasTerm<K extends IJsonQueryOptionalKeys>(key: K): (jsonQuery: IJsonQuery) => boolean {
  return jsonQuery => {
    return !!jsonQuery[key];
  };
}

export function getTermValue<K extends IJsonQueryTermKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => NonUndefinable<IJsonQuery[K]>['value'] | null {
  return jsonQuery => {
    const termField = jsonQuery[key];

    return isTerm<NonUndefinable<IJsonQuery[K]>['value']>(termField) ? termField.value : null;
  };
}

export function getTermsValue<K extends IJsonQueryTermsKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => NonEmptyArray<Unpacked<NonUndefinable<IJsonQuery[K]>['value']>> | null {
  return jsonQuery => {
    const termsField = jsonQuery[key];

    return isTerms<NonUndefinable<IJsonQuery[K]>['value']>(termsField) && termsField.value.length > 0
      ? (termsField.value as NonEmptyArray<Unpacked<NonUndefinable<IJsonQuery[K]>['value']>>)
      : null;
  };
}

export function getRangeValue<K extends IJsonQueryRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => IJsonQueryRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isRange(rangeField) ? rangeField.value : null;
  };
}

export function getDateRangeValue<K extends IJsonQueryDateRangeKeys>(
  key: K,
): (jsonQuery: IJsonQuery) => IJsonQueryDateRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isDateRange(rangeField) ? rangeField.value : null;
  };
}

export function getGeoValue(): (jsonQuery: IJsonQuery) => IJsonQueryGeo['value'] | null {
  return jsonQuery => (jsonQuery.geo ? jsonQuery.geo.value : null);
}
