import * as React from 'react';

import { OfferTypeSelect, IOfferTypeSelectProps } from './OfferTypeSelect';
import { HeaderBackable, ModalWindow } from '../../../../ui/ModalWindow';

export interface IOfferTypeSelectModalProps extends IOfferTypeSelectProps {
  open: boolean;
  onClose(): void;
}

export const OfferTypeSelectModal: React.FC<IOfferTypeSelectModalProps> = ({ open, onClose, ...sharedProps }) => {
  return (
    <ModalWindow
      header={<HeaderBackable title="Тип недвижимости" onBack={onClose} />}
      open={open}
      onClose={onClose}
      portal
    >
      <OfferTypeSelect {...sharedProps} />
    </ModalWindow>
  );
};
