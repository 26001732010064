import { PROPERTIES } from './properties';
import { IJsonQuery, IJsonQueryKeys } from './types';

export function convertProperties(jsonQuery: IJsonQuery): IJsonQuery {
  const keys = Object.keys(jsonQuery) as IJsonQueryKeys[];

  const nextJsonQuery = keys.reduce((nextJsonQuery, currentKey) => {
    if (!!nextJsonQuery[currentKey] && PROPERTIES[currentKey]) {
      const { converter } = PROPERTIES[currentKey];
      if (converter) {
        return converter(nextJsonQuery);
      }
    }

    return nextJsonQuery;
  }, jsonQuery);

  return nextJsonQuery;
}
