import * as React from 'react';
import { useContext } from '../../utils/filtersContext';
import { FOfferType } from '../../../../JsonQuery';
import { RootOfferType } from '../../components/filters/RootOfferType';
import { useDealType } from '../../utils/useDealType';
import { useOfferType } from '../../utils/useOfferType';

export const RootOfferTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action: 'setOfferType', arguments: [offerType] });
    },
    [onChange],
  );

  return <RootOfferType dealType={dealType} value={offerType} onChange={handleChange} />;
};
