import { EGeoNameActionType } from '../../actions';
import { TReduxActions } from '../../types/redux/actions';

const defaultState = '';

export function geoNameReducer(state: string = defaultState, action: TReduxActions): string {
  switch (action.type) {
    case EGeoNameActionType.SetGeoName:
      return action.payload;
  }

  return state;
}
