import { FDealType } from '../../../packages/JsonQuery';
import { ECategory } from '../../types/category';
import { ITrackingPageExtra, TTrackingPlacement, TTrackingPlacementType } from '../../types/tracking';

const DEAL_TYPE_MAP: Partial<Record<FDealType, TTrackingPlacementType>> = {
  [FDealType.Sale]: 'sale',
  [FDealType.RentLongterm]: 'rent_long',
  [FDealType.RentDaily]: 'rent_daily',
};

const PLACEMENT_BY_CATEGORY: Partial<Record<ECategory, TTrackingPlacement>> = {
  [ECategory.FlatSale]: 'sale',
  [ECategory.FlatRent]: 'rent',
};

interface IParams {
  dealType: FDealType;
  category: ECategory;
  queryString?: string;
  withEntrSource?: boolean;
}

export const getPageExtra = (extra: IParams): ITrackingPageExtra => {
  const placementType = DEAL_TYPE_MAP[extra.dealType];
  const entrSource = extra.withEntrSource ? 'lending_main_page' : undefined;
  const placement = PLACEMENT_BY_CATEGORY[extra.category];

  return {
    lending_main_page: true,
    placement,
    placement_type: placementType,
    querystring: extra.queryString,
    entr_source: entrSource,
  };
};
