import * as React from 'react';
import { IconChevronDownSmall16, IconChevronUpSmall16 } from '@cian/ui-kit';

import * as styles from './ExpandButton.css';

export interface IExpandButtonProps {
  expanded: boolean;
  onClick(): void;
}

export const ExpandButton: React.FC<IExpandButtonProps> = ({ expanded, onClick }) => {
  return (
    <button type="button" className={styles['button']} onClick={onClick}>
      {expanded ? 'Свернуть' : 'Посмотреть всё'}
      <span className={styles['icon']}>
        {expanded ? <IconChevronUpSmall16 color="primary_100" /> : <IconChevronDownSmall16 color="primary_100" />}
      </span>
    </button>
  );
};
