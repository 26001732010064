import * as React from 'react';
import { UIHeading4, UIText2 } from '@cian/ui-kit';
import { Container } from '../Container';
import { IRegionLink } from '../../types/regionLinks';
import * as styles from './RegionLinks.css';
import { CollapsedList } from '../CollapsedList';

const COLLAPSED_HEIGHT = 64;

interface IRegionLinksProps {
  links: IRegionLink[];
  onLinkClick(link: IRegionLink): void;
  onToggle(expanded: boolean): void;
}

export const RegionLinks: React.FC<IRegionLinksProps> = ({ links, onLinkClick, onToggle }) => {
  return (
    <div className={styles['container']}>
      <Container>
        <UIHeading4>Объявления по всей России</UIHeading4>
        <CollapsedList collapsedHeight={COLLAPSED_HEIGHT} onToggle={onToggle}>
          <div className={styles['links']}>
            {links.map((link, index) => (
              <a
                tabIndex={-1}
                key={link.url + index}
                href={link.url}
                className={styles['link']}
                onClick={() => onLinkClick(link)}
              >
                <UIText2 display="inline" color="primary_100">
                  {link.title}
                </UIText2>
              </a>
            ))}
          </div>
        </CollapsedList>
      </Container>
    </div>
  );
};
