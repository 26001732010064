import LRUCache from 'lru-cache';

import { DEFAULT_CACHE_BYPASS, DEFAULT_CACHE_OPTIONS, DEFAULT_TTL } from './constants';
import { IDependencies, ILruCacheParameters, TCacheKey } from './types';

export function lruCache<F extends (...args: unknown[]) => unknown>(
  fn: F,
  parameters: ILruCacheParameters<Awaited<ReturnType<F>>>,
) {
  const { key: functionKey, options = DEFAULT_CACHE_OPTIONS } = parameters;
  let cache = new LRUCache<TCacheKey, Awaited<ReturnType<F>>>({ ...DEFAULT_CACHE_OPTIONS, ...options });

  return async function funcWrapper(
    key: TCacheKey,
    dependencies: IDependencies,
    ...parameters: Parameters<F>
  ): Promise<Awaited<ReturnType<F>>> {
    const { config, logger, telemetry } = dependencies;

    const cacheTtl = config.get<number>(`${functionKey}.cache.ttl`) || DEFAULT_TTL;
    if (cache.ttl !== cacheTtl) {
      cache = new LRUCache<TCacheKey, Awaited<ReturnType<F>>>({ ...DEFAULT_CACHE_OPTIONS, ...options, ttl: cacheTtl });
    }

    const cacheBypass = config.get<boolean>(`${functionKey}.cache.bypass`) || DEFAULT_CACHE_BYPASS;

    const cachedData = cache.get(key);
    if (!cacheBypass && cachedData && cache.has(key)) {
      telemetry.increment(`${functionKey}.cache.hit`);

      return cachedData;
    }

    try {
      const result = (await fn(...parameters)) as Awaited<ReturnType<F>>;

      if (!cacheBypass) {
        cache.set(key, result);
      }

      telemetry.increment(`${functionKey}.cache.miss`);

      return result;
    } catch (ex) {
      if (!cacheBypass && cachedData) {
        // Если происходит ошибка при вызове функции fn и мы попадаем в catch, то предыдущее значение,
        // которое лежало в кеше, остаётся. Кроме того оно помечается как "протухшее" (ttl: 0).
        // При настройке allowStale: true протухшее значение лежит там до тех пор, пока его не заберём.
        // https://github.com/isaacs/node-lru-cache
        cache.set(key, cachedData, { ttl: 0 });

        telemetry.increment(`${functionKey}.cache.failed`);
        logger.warning(`Failed to update ${functionKey} cache`, ex);

        return cachedData;
      }

      throw ex;
    }
  };
}
