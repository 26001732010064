import { IOfferTypeSelectOptionsGroup } from './components/OfferTypeSelect';
import {
  OFFER_TYPES_RENT_RESIDENTIAL,
  OFFER_TYPES_RENT_COMMERCIAL,
  OFFER_TYPES_SALE_RESIDENTIAL,
  OFFER_TYPES_SALE_COMMERCIAL,
  OFFER_TYPES_DAILY_RESIDENTIAL,
} from './constants/offerTypes';
import { FDealType, FOfferType } from '../../../../../JsonQuery';

export function normalizeOfferType(offerType: FOfferType): FOfferType {
  return (offerType & FOfferType.Residential) !== 0 ? FOfferType.Residential : FOfferType.Commercial;
}

const OFFER_TYPES: { dealType: FDealType; offerType: FOfferType; list: IOfferTypeSelectOptionsGroup[] }[] = [
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_SALE_RESIDENTIAL,
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_SALE_COMMERCIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_RENT_RESIDENTIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_RENT_COMMERCIAL,
  },
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_DAILY_RESIDENTIAL,
  },
];

export function getOfferTypeList(dealType: FDealType, offerType: FOfferType): IOfferTypeSelectOptionsGroup[] {
  const type = OFFER_TYPES.find(t => t.dealType === dealType && t.offerType === normalizeOfferType(offerType));

  return type ? type.list : [];
}
