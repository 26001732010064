import { isAvailable, FDealType, FOfferType } from '../../../../JsonQuery';
import { IFilterList } from '../../types';

export const FILTER_LISTS: IFilterList[] = [
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [['rootOfferType'], ['flatType'], ['roomType', 'maxPrice'], ['geo']],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: [['rootOfferType'], ['roomType', 'maxPrice'], ['geo']],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Urban | FOfferType.House),
    list: [['rootOfferType'], ['geo'], ['dates', 'beds']],
  },
  {
    availability: isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Room | FOfferType.FlatShared),
    list: [['rootOfferType'], ['flatRooms', 'maxPrice'], ['geo']],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Suburban),
    list: [['rootOfferType'], ['minPrice', 'maxPrice'], ['geo']],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [['rootOfferType'], ['minPrice', 'maxPrice'], ['geo']],
  },
  {
    availability: isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Garage | FOfferType.Bed),
    list: [['rootOfferType'], ['minPrice', 'maxPrice'], ['geo']],
  },
];
