import * as React from 'react';
import * as styles from './GridCell.css';
import cn from 'classnames';

interface IGridCellProps {
  children: React.ReactNode;
  single: boolean;
}

export const GridCell: React.FC<IGridCellProps> = ({ children, single }) => {
  return <div className={cn(styles['grid-cell'], single && styles['full-width'])}>{children}</div>;
};
