import { IJsonQuery, term } from '../../../packages/JsonQuery';
import { EJsonQueryActionType } from '../../actions';
import { TReduxActions } from '../../types/redux/actions';

const defaultState: IJsonQuery = {
  _type: 'suburbansale',
  engine_version: term(2),
};

export function jsonQueryReducer(state: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case EJsonQueryActionType.JsonQueryChanged:
      return action.payload.nextJsonQuery;
  }

  return state;
}
