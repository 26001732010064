import { FOfferType } from '../../../../../../../JsonQuery';
import { IOfferTypeSelectOptionsGroup } from '../../components/OfferTypeSelect';

export const OFFER_TYPES_RENT_COMMERCIAL: IOfferTypeSelectOptionsGroup[] = [
  {
    options: [
      { label: 'Офис', value: FOfferType.Office },
      { label: 'Торговая площадь', value: FOfferType.TradeArea },
      { label: 'Склад', value: FOfferType.Warehouse },
      { label: 'Помещение свободного назначения', value: FOfferType.FreeAppointmentObject },
      { label: 'Общепит', value: FOfferType.PublicCatering },
      { label: 'Производство', value: FOfferType.Manufacture },
      { label: 'Автосервис', value: FOfferType.AutoService },
      { label: 'Готовый бизнес', value: FOfferType.Business },
      { label: 'Здание', value: FOfferType.Building },
      { label: 'Бытовые услуги', value: FOfferType.DomesticServices },
    ],
  },
  { options: [{ label: 'Коммерческая земля', value: FOfferType.CommercialLand }] },
];
