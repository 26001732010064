import * as React from 'react';
import { getTermsValue, NonEmptyArray, ERoomType } from '../../../../JsonQuery';
import { useContext } from '../../utils/filtersContext';
import { Rooms } from '../../components/filters/Rooms';
import { getRoomTypeLabel } from '../../utils/roomType';

interface IRoomType {
  label: string;
  value: ERoomType;
}

const ROOM_TYPES: IRoomType[] = [
  { label: '1', value: ERoomType.FlatOneRoom },
  { label: '2', value: ERoomType.FlatTwoRooms },
  { label: '3', value: ERoomType.FlatThreeRooms },
  { label: '4', value: ERoomType.FlatFourRooms },
  { label: '5', value: ERoomType.FlatFiveRooms },
  { label: '6+', value: ERoomType.FlatManyRooms },
  { label: 'Студия', value: ERoomType.Studio },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

export const RoomTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const roomType = getTermsValue('room')(jsonQuery);

  const handleChange = React.useCallback(
    (roomType: NonEmptyArray<ERoomType> | null) => {
      onChange({ action: 'setRoomTypes', arguments: [roomType] });
    },
    [onChange],
  );

  const label = React.useMemo(() => (roomType ? getRoomTypeLabel(roomType) : null), [roomType]);

  return (
    <Rooms value={roomType} onChange={handleChange} modalTitle="Количество комнат" label={label} options={ROOM_TYPES} />
  );
};
