import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { Application } from '../../../shared/containers/App';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { IReduxStore } from '../../../shared/types/redux';
import { MicrofrontendContext } from '@cian/mf-react';
import { IBrowserMicrofrontend } from '@cian/mf-registry/browser';

interface IDependencies {
  store: IReduxStore;
  context: IApplicationContext;
  microfrontend: IBrowserMicrofrontend;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context, microfrontend } = dependencies;
  const { config } = context;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(
    rootElement,
    <MicrofrontendContext.Provider value={microfrontend}>
      <Application context={context} reduxStore={store} />
    </MicrofrontendContext.Provider>,
  );
}
