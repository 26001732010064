import { UIHeading2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './OfferTypeSelect.css';
import { IOfferTypeSelectOptionsGroup, IOfferTypeSelectOption } from './types';
import { FOfferType, isAvailableOfferTypeCombination } from '../../../../../../../JsonQuery';
import { ActionButtons } from '../../../../ui/ModalWindow';
import { CellGroup, CellCheckbox } from '../../../../ui/CellGroup';

export interface IOfferTypeSelectProps {
  options: IOfferTypeSelectOptionsGroup[];
  selected: FOfferType[];

  onSelect(value: FOfferType[]): void;
}

export const OfferTypeSelect: React.FC<IOfferTypeSelectProps> = ({ options, selected, onSelect }) => {
  const [selectedOptions, setSelectedOptions] = React.useState<FOfferType[]>(selected);

  const handleSelect = React.useCallback(
    (option: IOfferTypeSelectOption) => {
      if (selectedOptions.includes(option.value)) {
        if (selectedOptions.length > 1) {
          setSelectedOptions(selectedOptions.filter(o => o !== option.value));
        }
      } else {
        if (
          isAvailableOfferTypeCombination(
            [...selectedOptions, option.value].reduce(
              (previousValue, currentValue) => previousValue | currentValue,
              FOfferType.Unexpected,
            ),
          )
        ) {
          setSelectedOptions([...selectedOptions, option.value]);
        } else {
          setSelectedOptions([option.value]);
        }
      }
    },
    [selectedOptions],
  );

  const handleApply = React.useCallback(() => {
    onSelect(selectedOptions);
  }, [onSelect, selectedOptions]);

  return (
    <div className={styles['container']}>
      <div className={styles['options']}>
        {options.map((optionsGroup, optionsGroupIndex) => (
          <React.Fragment key={`optionsGroup_${optionsGroupIndex}`}>
            {optionsGroup.label && (
              <div className={styles['header']}>
                <UIHeading2>{optionsGroup.label}</UIHeading2>
              </div>
            )}
            <CellGroup>
              {optionsGroup.options.map((option, optionIndex) => (
                <CellCheckbox
                  checked={selectedOptions.includes(option.value)}
                  key={`option_${optionsGroupIndex}_${optionIndex}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </CellCheckbox>
              ))}
            </CellGroup>
          </React.Fragment>
        ))}
      </div>
      <ActionButtons onApply={handleApply} />
    </div>
  );
};
