import * as React from 'react';
import { IconChevronRight16, UIHeading2, UIText1 } from '@cian/ui-kit';
import { IQuickLink } from '../../types/quickLinks';
import { Container } from '../Container';
import * as styles from './QuickLinks.css';

interface IQuickLinksProps {
  title: string;
  links: IQuickLink[];
  onLinkClick(link: IQuickLink): void;
}

export const QuickLinks: React.FC<IQuickLinksProps> = ({ title, links, onLinkClick }) => {
  return (
    <div className={styles['container']}>
      <Container>
        <UIHeading2>{title}</UIHeading2>
        <ul className={styles['list']}>
          {links.map((link, index) => (
            <li key={link.url + index} className={styles['item']}>
              <a href={link.url} className={styles['item-link']} onClick={() => onLinkClick(link)}>
                <span className={styles['item-title']}>
                  <UIText1>{link.title}</UIText1>
                </span>
                {link.count && (
                  <span className={styles['item-count']}>
                    <UIText1 color="gray60_100">{link.count}</UIText1>
                  </span>
                )}
                <div className={styles['item-icon']}>
                  <IconChevronRight16 color="primary_100" display="block" />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};
