import * as React from 'react';
import * as styles from './Grid.css';

interface IGridProps {
  children: React.ReactNode;
}

export const Grid: React.FC<IGridProps> = ({ children }) => {
  return <div className={styles['grid']}>{children}</div>;
};
