const WEEKDAYS = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const MONTHS_SHORT = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'нояб.', 'дек.'];

export const formatDate = (date: Date, shouldPrintYear: boolean): string => {
  const shortYear = date.getFullYear().toString().substring(2);
  const year = shouldPrintYear ? shortYear : null;

  const weekdayNumber = date.getDay();
  const weekday = `${WEEKDAYS[weekdayNumber]},`;

  const dayOfMonth = date.getDate();

  const monthNumber = date.getMonth();
  const month = MONTHS_SHORT[monthNumber];

  return [weekday, dayOfMonth, month, year].filter(Boolean).join(' ');
};
