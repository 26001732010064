import * as React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { Container } from '../Container';
import * as styles from './Seo.css';
import { CollapsedText } from '../CollapsedText';

const COLLAPSED_HEIGHT = 92;

interface ISeoProps {
  title: string;
  texts: string[];
}

export const Seo: React.FC<ISeoProps> = ({ title, texts }) => {
  return (
    <div className={styles['container']}>
      <Container>
        <UIHeading2 as="h1">{title}</UIHeading2>
        <CollapsedText collapsedHeight={COLLAPSED_HEIGHT}>
          {texts.map((text, index) => (
            /* eslint-disable-next-line react/no-danger */
            <div key={`seo-text-${index}`} className={styles['text']} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </CollapsedText>
      </Container>
    </div>
  );
};
