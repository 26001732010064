import { ConfigurationError } from '@cian/peperrors/shared';
import { useContext } from '../../utils/filtersContext/filtersContext';
import * as React from 'react';
import { FILTERS, FILTER_LISTS } from '../../constants/filters';
import { Grid, GridCell } from '../../components/ui/Grid';

export const FiltersContainer: React.FC = () => {
  const context = useContext();

  if (!context) {
    throw new ConfigurationError({
      domain: 'Filters',
      message: "Filters used outside of it's context",
    });
  }

  const { jsonQuery } = context;

  const availableFilters = React.useMemo(
    () => FILTER_LISTS.filter(f => f.availability(jsonQuery)).flatMap(({ list }) => list),
    [jsonQuery],
  );

  return (
    <Grid>
      {availableFilters.map(filterRow => {
        return filterRow.map(filterKey => {
          const Component = FILTERS[filterKey];

          return (
            <GridCell single={filterRow.length === 1} key={`filter_${filterKey}`}>
              <Component />
            </GridCell>
          );
        });
      })}
    </Grid>
  );
};
