import { BedsContainer } from '../../containers/BedsContainer';
import { DatesContainer } from '../../containers/DatesContainer';
import { FlatRoomsContainer } from '../../containers/FlatRoomsContainer';
import { FlatTypeContainer } from '../../containers/FlatTypeContainer';
import { GeoContainer } from '../../containers/GeoContainer';
import { MaxPriceContainer } from '../../containers/MaxPriceContainer/MaxPriceContainer';
import { MinPriceContainer } from '../../containers/MinPriceContainer';
import { RoomTypeContainer } from '../../containers/RoomTypeContainer';
import { RootDealTypeContainer } from '../../containers/RootDealTypeContainer';
import { RootOfferTypeContainer } from '../../containers/RootOfferTypeContainer';
import { TFilter } from '../../types';

export const FILTERS: { [key in TFilter]: React.ComponentType } = {
  beds: BedsContainer,
  dates: DatesContainer,
  flatType: FlatTypeContainer,
  geo: GeoContainer,
  flatRooms: FlatRoomsContainer,
  minPrice: MinPriceContainer,
  maxPrice: MaxPriceContainer,
  roomType: RoomTypeContainer,
  rootDealType: RootDealTypeContainer,
  rootOfferType: RootOfferTypeContainer,
};
