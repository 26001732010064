import * as React from 'react';

import { Cell, ICellProps } from '../Cell';
import { IconSuccess16 } from '@cian/ui-kit';

interface ICellSelectProps extends Omit<ICellProps, 'accessory'> {
  selected?: boolean;
}

export const CellSelect: React.FC<ICellSelectProps> = ({ selected, onClick, ...sharedProps }) => {
  const handleClick = React.useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Cell {...sharedProps} accessory={selected ? <IconSuccess16 color="primary_100" /> : null} onClick={handleClick} />
  );
};
