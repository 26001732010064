import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { ERoomsTotal, NonEmptyArray } from '../../../JsonQuery';
import { plural } from '@cian/utils';

const FlatRoomTypeNames: { [key: number]: string } = {
  [ERoomsTotal.One]: '1',
  [ERoomsTotal.Two]: '2',
  [ERoomsTotal.Three]: '3',
  [ERoomsTotal.Four]: '4',
  [ERoomsTotal.Five]: '5',
};

export function getFlatRoomTypeLabel(rooms: NonEmptyArray<ERoomsTotal>): string {
  const roomTypeName = Object.keys(FlatRoomTypeNames)
    .map<string | null>(type => (rooms.includes(Number(type)) ? FlatRoomTypeNames[Number(type)] : null))
    .filter(Boolean)
    .join(', ')
    .concat(plural(rooms[rooms.length - 1], ['комната', 'комнаты', 'комнат']));

  return roomTypeName && capitalizeFirstLetter(roomTypeName);
}
