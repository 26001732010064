import { ModalFullscreen } from '@cian/ui-kit/modal';
import { UIText1 } from '@cian/ui-kit/typography';
import loadable from '@loadable/component';
import { addDays, startOfToday } from 'date-fns';
import * as React from 'react';
import { RangeKeyDict } from 'react-date-range';

import * as styles from './DateRangeSelectModal.css';
import { getIsResetButtonShow } from './utils';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../../../../LoadableHelpers';
import { useContext } from '../../../../../utils/filtersContext';
import { HeaderBackable } from '../../../../ui/ModalWindow';

export interface IDateRangeSelectModalProps {
  initialFrom: Date | undefined;
  initialTo: Date | undefined;
  min: Date;
  max: Date;
  onChange(from: Date | undefined, to: Date | undefined): void;
  onClose(): void;
}

export const DateRangeModalContentLoadable = loadable(() => import('./DateRangeModalContent'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const DateRangeSelectModal: React.FC<IDateRangeSelectModalProps> = props => {
  const { logger } = useContext();
  const { initialFrom, initialTo, min, max, onClose, onChange } = props;

  const today = startOfToday();
  const initialFromValue = initialFrom || today;
  const initialToValue = initialTo || addDays(initialFromValue, 1);

  const [from, setFromDate] = React.useState<Date | undefined>(initialFromValue);
  const [to, setToDate] = React.useState<Date | undefined>(initialToValue);

  const isApplyButtonDisabled = !from;
  const isResetButtonShow = React.useMemo(() => {
    return getIsResetButtonShow({
      initialFrom: initialFromValue,
      initialTo: initialToValue,
      from: from!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      to: to!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    });
  }, [from, to]);

  const selection = {
    startDate: from,
    endDate: to,
    key: 'selection',
  };

  const handleRetryLoadContent = React.useCallback(() => {
    DateRangeModalContentLoadable.load();
  }, []);

  const handleChange = React.useCallback(
    (data: RangeKeyDict) => {
      const { startDate, endDate } = data.selection;

      setFromDate(startDate);
      setToDate(endDate);
    },
    [setFromDate, setToDate],
  );

  const handleResetClick = React.useCallback(() => {
    setFromDate(initialFromValue);
    setToDate(initialToValue);
  }, [setFromDate, setToDate]);

  const handleApplyClick = React.useCallback(() => {
    const fromValue = from!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const toValue = to || addDays(fromValue, 1);

    if (!to) {
      setToDate(toValue);
    }

    onChange(fromValue, toValue);
    onClose();
  }, [from, to]);

  return (
    <ModalFullscreen open onClose={onClose}>
      <HeaderBackable
        title="Даты проживания"
        right={
          isResetButtonShow ? (
            <button
              className={styles['reset_button']}
              onClick={handleResetClick}
              data-testid="DateRangeSelectModal.ResetButton"
            >
              <UIText1 color="primary_100">Сбросить</UIText1>
            </button>
          ) : undefined
        }
        onBack={onClose}
      />

      <LoadableErrorBoundary logger={logger} onRetry={handleRetryLoadContent}>
        <DateRangeModalContentLoadable
          min={min}
          max={max}
          applyButtonDisabled={isApplyButtonDisabled}
          selection={selection}
          onChange={handleChange}
          onApplyClick={handleApplyClick}
        />
      </LoadableErrorBoundary>
    </ModalFullscreen>
  );
};
