import { IJsonQuery, JsonQuery, NonEmptyArray, TModifier } from '../../../JsonQuery';

export function modifyJsonQuery(jsonQuery: IJsonQuery, modifiers: NonEmptyArray<TModifier>): IJsonQuery {
  const jq = new JsonQuery(jsonQuery);

  const setRegionIndex = modifiers.findIndex(m => m.action === 'setRegion');
  if (setRegionIndex !== -1) {
    modifiers.splice(setRegionIndex + 1, 0, { action: 'clearGeo', arguments: [] });
  }

  modifiers.forEach(modifier => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (jq[modifier.action] as any)(...modifier.arguments);
  });

  return jq.toJSON();
}
