import { InputAdornment } from '@cian/ui-kit';
import * as React from 'react';
import * as styles from './Price.css';
import { MaskedInput } from '../../ui/MaskedInput';

export interface IPriceProps {
  placeholder: string;
  onChange(value: number | null): void;
  value?: number | null;
  min?: number;
  internalValueMin?: number | null;
}

export const Price: React.FC<IPriceProps> = ({ placeholder, onChange, value, min = 0, internalValueMin = null }) => {
  const handleChange = React.useCallback(
    (valueRaw: string) => {
      const newValue = valueRaw ? Number(valueRaw) : null;
      if (newValue === value) {
        return;
      }

      if (onChange) {
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const inputValue = React.useMemo(() => {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }

    return String(value);
  }, [value]);

  return (
    <MaskedInput
      min={getMinForMaxField(internalValueMin, min)}
      max={999999999999}
      rightAdornment={
        <InputAdornment>
          <span className={styles['postfix']}>{'\u20bd'}</span>
        </InputAdornment>
      }
      placeholder={placeholder}
      mask={Number}
      scale={0}
      thousandsSeparator={' '}
      mapToRadix={[',', '.', 'Б', 'Ю']}
      value={inputValue}
      onComplete={handleChange}
    />
  );
};

function getMinForMaxField(internalMin: number | null, min: number | undefined): number | undefined {
  if (internalMin !== null && typeof min !== 'undefined') {
    return internalMin < min ? min : internalMin;
  } else if (internalMin !== null) {
    return internalMin;
  } else if (typeof min !== 'undefined') {
    return min;
  }

  return undefined;
}
