import { formatDate } from './formatDate';

export const formatInputValue = (from: Date | undefined, to: Date | undefined): string => {
  if (!from || !to) {
    return '';
  }

  const today = new Date();
  const isSameYear = from.getFullYear() === to.getFullYear();
  const isCurrentYear = from.getFullYear() === today.getFullYear();
  const shouldPrintYear = !isSameYear || !isCurrentYear;

  return `${formatDate(from, shouldPrintYear)} – ${formatDate(to, shouldPrintYear)}`;
};
