import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import * as React from 'react';

import { modifyJsonQuery } from '../../utils/modifyJsonQuery';
import { IJsonQuery, NonEmptyArray, TModifier } from '../../../../JsonQuery';
import { Context } from '../../utils/filtersContext';
import { IOnChangeParameters } from '../../types/filters';
import { IFiltersContext } from '../../types/context';
import { ECategory } from '../../../../../shared/types/category';

interface IProviderProps {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
  jsonQuery: IJsonQuery;
  onChange(parameters: IOnChangeParameters): void;
  geoName: string;
  setGeoName(geoName: string): void;
  regions: number[];
  subdomain: string;
  category: ECategory;
}

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({
  children,
  logger,
  config,
  httpApi,
  jsonQuery,
  onChange,
  geoName,
  setGeoName,
  regions,
  subdomain,
  category,
}) => {
  const handleChange = React.useCallback(
    (...modifiers: NonEmptyArray<TModifier>) => {
      onChange({
        appliedModifiers: modifiers,
        nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
      });
    },
    [jsonQuery, onChange],
  );

  const context = React.useMemo<IFiltersContext>(
    () => ({
      logger,
      config,
      httpApi,
      jsonQuery,
      onChange: handleChange,
      geoName,
      setGeoName,
      regions,
      subdomain,
      category,
    }),
    [logger, config, httpApi, jsonQuery, handleChange, geoName, setGeoName, regions, subdomain, category],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
