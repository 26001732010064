import { TCacheOptions } from './types';

export const DEFAULT_TTL = 10 * 60 * 1000;
export const DEFAULT_CACHE_BYPASS = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_CACHE_OPTIONS: TCacheOptions<any> = {
  max: 500,
  ttl: DEFAULT_TTL,
  allowStale: true,
};
