import { CancellationToken } from '@cian/http-api';

import { IJsonQuery } from '../../../packages/JsonQuery';
import { prepareSearchMeta } from '../../mappers/prepareSearchMeta';
import { fetchGetMeta, TGetMetaResponse } from '../../repositories/search-offers-index/v1/get-meta';
import { IApplicationContext } from '../../types/applicationContext';
import { ISearchMeta } from '../../types/searchMeta';
import { ResponseError } from '../../errors/responseError';

interface IFetchSearchMetaParameters {
  jsonQuery: IJsonQuery;
  cancelationTokenKey?: string;
}

export async function fetchSearchMeta(
  context: IApplicationContext,
  { jsonQuery, cancelationTokenKey = 'fetchSearchMeta' }: IFetchSearchMetaParameters,
): Promise<ISearchMeta> {
  const { cancellationTokenManager, httpApi } = context;

  let cancellationToken: CancellationToken | undefined;
  if (cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious(cancelationTokenKey);
  }

  const searchMetaData: TGetMetaResponse = await fetchGetMeta({
    httpApi,
    parameters: {
      jsonQuery,
    },
    config: { cancellationToken },
  });

  if (searchMetaData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchSearchMeta',
      message: searchMetaData.response.message || '',
      details: {
        error: JSON.stringify(searchMetaData.response.errors),
      },
    });
  }

  return prepareSearchMeta(searchMetaData.response);
}
