import { FOfferType } from '../../../../../../../JsonQuery';
import { IOfferTypeSelectOptionsGroup } from '../../components/OfferTypeSelect';

export const OFFER_TYPES_DAILY_RESIDENTIAL: IOfferTypeSelectOptionsGroup[] = [
  {
    label: 'Квартиры и комнаты',
    options: [
      { label: 'Квартира', value: FOfferType.Flat },
      { label: 'Комната', value: FOfferType.Room },
      { label: 'Койко-место', value: FOfferType.Bed },
    ],
  },
  {
    label: 'Дома и участки',
    options: [{ label: 'Дом, дача', value: FOfferType.House }],
  },
];
