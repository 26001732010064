import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { ERoomType, NonEmptyArray } from '../../../JsonQuery';

const RoomTypeNames: { [key: number]: string } = {
  [ERoomType.FlatOneRoom]: '1',
  [ERoomType.FlatTwoRooms]: '2',
  [ERoomType.FlatThreeRooms]: '3',
  [ERoomType.FlatFourRooms]: '4',
  [ERoomType.FlatFiveRooms]: '5',
  [ERoomType.FlatManyRooms]: '6+',
  [ERoomType.FlatFreeLayout]: 'своб.планировку',
  [ERoomType.Studio]: 'студию',
};

export function getRoomTypeLabel(rooms: NonEmptyArray<ERoomType>): string {
  const filteredTypeName = Object.keys(RoomTypeNames)
    .map(type => (rooms.includes(Number(type)) ? Number(type) : null))
    .filter(Boolean);

  const roomTypeName = filteredTypeName
    .map((type, index) => {
      const typeEnum = Number(type);
      const roomStr = RoomTypeNames[typeEnum];
      const specialRooms = [ERoomType.FlatFreeLayout, ERoomType.Studio];
      const isLastSimpleRoom = index === filteredTypeName.length - 1 && !specialRooms.includes(typeEnum);
      const isLastBeforeSpecialRooms = specialRooms.includes(filteredTypeName[index + 1] as ERoomType);

      return isLastSimpleRoom || isLastBeforeSpecialRooms ? `${roomStr} комн.` : roomStr;
    })
    .join(', ');

  return roomTypeName && capitalizeFirstLetter(roomTypeName);
}
