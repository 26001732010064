import * as React from 'react';
import { getRangeValue } from '../../../../JsonQuery';
import { Price } from '../../components/filters/Price';
import { useContext } from '../../utils/filtersContext';
import { useDebouncedCallback } from '../../utils/useDebouncedCallback';

export const MinPriceContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const price = getRangeValue('price')(jsonQuery);

  const handlePriceChange = useDebouncedCallback((gte: number | null) => {
    onChange({ action: 'setPriceMin', arguments: [gte] });
  }, 300);

  return <Price placeholder="Цена от" value={price?.gte} onChange={handlePriceChange} />;
};
