import { IconCalendar16 } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Dates.css';

export interface IDatesProps {
  value: string;
  onClick(): void;
}

export const Dates: React.FC<IDatesProps> = props => {
  const { value, onClick } = props;

  return (
    <button className={styles['button']} onClick={onClick}>
      <span className={styles['icon_wrapper']}>
        <IconCalendar16 display="inline-block" color="gray40_100" />
      </span>

      <span className={classNames(styles['button_text'], !value && styles['button_text_empty'])}>
        {value || 'Даты'}
      </span>
    </button>
  );
};
