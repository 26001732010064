import { lruCache } from '../../../packages/LRUCache';
import { ResponseError } from '../../errors/responseError';
import { IApplicationContext } from '../../types/applicationContext';
import {
  TGetRegionExtensionItemResponse,
  fetchGetRegionExtensionItem,
} from '../../repositories/countryside-region-extension/v1/get-region-extension-item';

interface IFetchRegionExtensionParams {
  regionId: number;
}

const fetchRegionExtension = async (
  { httpApi, logger, config }: IApplicationContext,
  parameters: IFetchRegionExtensionParams,
) => {
  if (!config.get<boolean>('Countryside.MobileMainPageRegionExtension.Enabled')) {
    return null;
  }

  try {
    const data: TGetRegionExtensionItemResponse = await fetchGetRegionExtensionItem({
      httpApi,
      parameters,
    });

    if (data.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchRegionExtension',
        message: data.response.message || 'Неизвестная ошибка',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: data.response.errors as any,
        },
      });
    }

    return data.response.regionItem;
  } catch (error) {
    logger.warning(error, { degradation: 'Расширение региона' });

    return null;
  }
};

const fetchRegionExtensionWithCache = lruCache(fetchRegionExtension, { key: 'regionExtension' });

export const fetchRegionExtensionCached = (context: IApplicationContext, parameters: IFetchRegionExtensionParams) => {
  return fetchRegionExtensionWithCache(`regionExtension.${parameters.regionId}`, context, context, parameters);
};
