import { resetTerms } from './helpers';
import { PROPERTIES } from './properties';
import { IJsonQuery, IJsonQueryOptionalKeys } from './types';

export function unsetUnavailableProperties(jsonQuery: IJsonQuery): IJsonQuery {
  const keys = Object.keys(jsonQuery) as IJsonQueryOptionalKeys[];

  const unavailableKeys = keys.reduce((unavailableKeys, currentKey) => {
    if (!!jsonQuery[currentKey] && PROPERTIES[currentKey]) {
      const { availability } = PROPERTIES[currentKey];
      if (availability && !availability(jsonQuery)) {
        return [...unavailableKeys, currentKey];
      }
    }

    return unavailableKeys;
  }, []);

  if (unavailableKeys.length === 0) {
    return jsonQuery;
  }

  return unsetUnavailableProperties(resetTerms(unavailableKeys)(jsonQuery));
}
