import * as React from 'react';

import { FiltersContainer } from '../FiltersContainer';
import { QuickLinksContainer } from '../QuickLinksContainer';
import { RegionLinksContainer } from '../RegionLinksContainer';
import { SeoContainer } from '../SeoContainer';
import { RecommendationsContainer } from '../RecommendationsContainer';

export const ContentContainer = () => {
  return (
    <>
      <FiltersContainer />
      <RecommendationsContainer />
      <QuickLinksContainer />
      <RegionLinksContainer />
      <SeoContainer />
    </>
  );
};
