import { Button } from '@cian/ui-kit/button';
import { IconChevronLeft24 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './HeaderBackable.css';
import { UIHeading4 } from '@cian/ui-kit';

interface IHeaderBackableProps {
  title: string;
  onBack(): void;
  right?: JSX.Element;
}

export const HeaderBackable: React.FC<IHeaderBackableProps> = ({ onBack, title, right }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['left']}>
        <div className={styles['arrow']}>
          <Button
            theme="fill_white_primary"
            size="XS"
            beforeIcon={<IconChevronLeft24 display="block" />}
            onClick={onBack}
          />
        </div>
      </div>
      <div className={styles['center']}>
        <UIHeading4>{title}</UIHeading4>
      </div>
      <div className={styles['right']}>{right}</div>
    </div>
  );
};
