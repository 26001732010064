import * as React from 'react';
import { useContext } from '../../utils/filtersContext';
import { FDealType } from '../../../../JsonQuery';
import { RootDealType } from '../../components/filters/RootDealType';
import { useDealType } from '../../utils/useDealType';
import { trackOpenDealTypes } from './tracking';
import { ECategory } from '../../../../../shared/types/category';

export const RootDealTypeContainer: React.FC = () => {
  const { category } = useContext();
  const dealType = useDealType();

  const handleChange = React.useCallback(
    (dealType: FDealType) => {
      switch (true) {
        case category === ECategory.FlatSale && dealType === FDealType.RentLongterm:
          window.location.href = '/snyat/';
          break;

        case category === ECategory.FlatRent && dealType === FDealType.Sale:
          window.location.href = '/kupit/';
          break;

        case dealType === FDealType.RentDaily:
          window.location.href = '/posutochno/';
          break;
      }
    },
    [category],
  );

  return (
    <RootDealType value={dealType} onChange={handleChange} onOpen={() => trackOpenDealTypes(dealType, category)} />
  );
};
