import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';

export function useDebouncedCallback<T extends (...args: unknown[]) => unknown>(callback: T, delay: number): T {
  const debouncedFunction = React.useRef<T>(callback);
  debouncedFunction.current = callback;

  const isComponentUnmounted = React.useRef(false);
  const functionTimeoutHandler = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(
    () => () => {
      isComponentUnmounted.current = true;
    },
    [],
  );

  return useEventCallback(((...args) => {
    if (functionTimeoutHandler.current) {
      clearTimeout(functionTimeoutHandler.current);
    }

    functionTimeoutHandler.current = setTimeout(() => {
      functionTimeoutHandler.current = null;

      if (!isComponentUnmounted.current) {
        debouncedFunction.current(...args);
      }
    }, delay);
  }) as T);
}
