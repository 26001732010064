import { CheckboxButtonGroup as UiKitCheckboxButtonGroup } from '@cian/ui-kit/checkbox';
import { IOption as IUiKitOption } from '@cian/ui-kit/types';
import * as React from 'react';
import { NonEmptyArray } from '../../../../../JsonQuery';

type TBaseCheckboxButtonGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof UiKitCheckboxButtonGroup>,
  'options' | 'value' | 'onChange'
>;
type TValue<T> = NonEmptyArray<T> | null;

interface IOption<T> {
  value: T | null;
  label: string | number;
}

export interface ICheckboxButtonGroupProps<T> extends TBaseCheckboxButtonGroupProps {
  options: IOption<T>[];
  value: TValue<T>;
  onChange(value: TValue<T>): void;
}

/**
 * Обертка над китовым CheckboxButtonGroup
 * Для поддержки null и boolean в качестве значения
 */
export function CheckboxButtonGroup<T>(props: ICheckboxButtonGroupProps<T>) {
  const { onChange, options: optionsProp, value: valueProp } = props;
  const { options, optionsMap } = React.useMemo(() => {
    return optionsProp.reduce(
      (result, item) => {
        const value = String(item.value);

        result.options.push({
          ...item,
          value,
        });

        result.optionsMap[value] = item.value;

        return result;
      },
      {
        options: [] as IUiKitOption[],
        optionsMap: {} as { [value: string]: T | null },
      },
    );
  }, [optionsProp]);
  const value = React.useMemo(() => {
    if (valueProp === null) {
      return [String(null)];
    }

    return valueProp.map(v => String(v));
  }, [valueProp]);

  const isNullValue = valueProp === null;
  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string[]) => {
      const unmappedValue = value.map(v => optionsMap[v] ?? null);

      function getNextValue(): TValue<T> | null {
        if (isNullValue) {
          if (unmappedValue.length > 1) {
            return unmappedValue.filter(value => value !== null) as NonEmptyArray<T>;
          }

          return null;
        } else {
          if (unmappedValue.length < 1 || unmappedValue.indexOf(null) !== -1) {
            return null;
          }

          return unmappedValue as NonEmptyArray<T>;
        }
      }

      onChange(getNextValue());
    },
    [isNullValue, optionsMap, onChange],
  );

  return <UiKitCheckboxButtonGroup {...props} options={options} value={value} onChange={handleChange} />;
}
